import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "scheduler",
  class: "scheduler"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchedulerNavigation = _resolveComponent("SchedulerNavigation")
  const _component_SchedulerDailyView = _resolveComponent("SchedulerDailyView")
  const _component_SchedulerWeeklyView = _resolveComponent("SchedulerWeeklyView")
  const _component_SchedulerMonthlyView = _resolveComponent("SchedulerMonthlyView")
  const _component_SchedulerListView = _resolveComponent("SchedulerListView")
  const _component_AppEventModal = _resolveComponent("AppEventModal")
  const _component_SchedulerNewEventModal = _resolveComponent("SchedulerNewEventModal")
  const _component_SchedulerCalendarCalendarConfirmation = _resolveComponent("SchedulerCalendarCalendarConfirmation")
  const _component_SchedulerTimingPlaceholderModal = _resolveComponent("SchedulerTimingPlaceholderModal")
  const _component_SchedulerQuantityPlaceholderModal = _resolveComponent("SchedulerQuantityPlaceholderModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_SchedulerNavigation, {
        modelValue: _ctx.currentDay,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentDay = $event)),
        "view-type": _ctx.viewType,
        "view-types": _ctx.viewTypes,
        "onUpdate:viewType": _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeViewType($event)))
      }, null, 8, ["modelValue", "view-type", "view-types"]),
      _createVNode("div", null, [
        (_ctx.viewType === 'day')
          ? (_openBlock(), _createBlock(_component_SchedulerDailyView, {
              key: 0,
              resources: _ctx.resources,
              "dragged-event": _ctx.draggedEvent,
              "current-day": _ctx.currentDay,
              loading: _ctx.loading,
              events: _ctx.events,
              "component-key": _ctx.componentKey,
              onShowEvent: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showEvent($event, true))),
              "onUpdate:draggedEvent": _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateDraggedEvent($event))),
              onCreateNewEvent: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showNewEventModal($event.resource, $event?.start))),
              onCreateNewPlaceholder: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showNewPlaceholderModal($event.resource, $event?.start))),
              "onChange:eventDateTiming": _cache[7] || (_cache[7] = ($event: any) => (_ctx.changeEventDateTiming($event.data, $event.resourceId))),
              "onChange:eventDateQuantity": _cache[8] || (_cache[8] = ($event: any) => (_ctx.changeEventDateQuantity($event))),
              "onChange:eventDuration": _cache[9] || (_cache[9] = ($event: any) => (_ctx.changeEventDuration($event.data, $event.resourceId))),
              "onChange:placeholderDuration": _cache[10] || (_cache[10] = ($event: any) => (_ctx.changePlaceholderDuration($event.data))),
              onReorderEvents: _cache[11] || (_cache[11] = ($event: any) => (_ctx.reorderEvents($event.data, $event.resourceId))),
              onDeletePlaceholder: _cache[12] || (_cache[12] = ($event: any) => (_ctx.deletePlaceholder($event)))
            }, null, 8, ["resources", "dragged-event", "current-day", "loading", "events", "component-key"]))
          : (_ctx.viewType === 'week')
            ? (_openBlock(), _createBlock(_component_SchedulerWeeklyView, {
                key: 1,
                "current-day": _ctx.currentDay,
                loading: _ctx.loading,
                events: _ctx.events,
                onShowEvent: _cache[13] || (_cache[13] = ($event: any) => (_ctx.showEvent($event, true))),
                onSetDate: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setDayViewAndSetDate($event)))
              }, null, 8, ["current-day", "loading", "events"]))
            : (_ctx.viewType === 'month')
              ? (_openBlock(), _createBlock(_component_SchedulerMonthlyView, {
                  key: 2,
                  "current-day": _ctx.currentDay,
                  loading: _ctx.loading,
                  events: _ctx.events,
                  onShowEvent: _cache[15] || (_cache[15] = ($event: any) => (_ctx.showEvent($event, true))),
                  onSetDate: _cache[16] || (_cache[16] = ($event: any) => (_ctx.setDayViewAndSetDate($event)))
                }, null, 8, ["current-day", "loading", "events"]))
              : (_ctx.viewType === 'list')
                ? (_openBlock(), _createBlock(_component_SchedulerListView, {
                    key: 3,
                    "current-day": _ctx.currentDay,
                    loading: _ctx.loading,
                    events: _ctx.events,
                    onShowEvent: _cache[17] || (_cache[17] = ($event: any) => (_ctx.showEvent($event, true))),
                    onSetDate: _cache[18] || (_cache[18] = ($event: any) => (_ctx.setDayViewAndSetDate($event)))
                  }, null, 8, ["current-day", "loading", "events"]))
                : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_AppEventModal, {
      id: _ctx.eventId,
      modelValue: _ctx.isEventModalActive,
      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_ctx.isEventModalActive = $event)),
      onClose: _ctx.closeEvent,
      onStatusChange: _ctx.fetchEvents,
      onEventCancel: _ctx.fetchEvents,
      onEdit: _ctx.fetchEvents,
      onRepairDateChanged: _cache[20] || (_cache[20] = ($event: any) => (_ctx.changeEventDateModal($event, _ctx.eventId))),
      onEventFetched: _ctx.onEventFetch
    }, null, 8, ["id", "modelValue", "onClose", "onStatusChange", "onEventCancel", "onEdit", "onEventFetched"]),
    _createVNode(_component_SchedulerNewEventModal, {
      modelValue: _ctx.isNewEventModalShown,
      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (_ctx.isNewEventModalShown = $event)),
      metadata: _ctx.eventMetadata,
      "hour-to-select": _ctx.modalWithHours,
      onClose: _ctx.closeNewEventModal,
      onCreateInCalendar: _ctx.fetchEvents,
      onStartHourChanged: _cache[22] || (_cache[22] = ($event: any) => (_ctx.startHourChanged($event)))
    }, null, 8, ["modelValue", "metadata", "hour-to-select", "onClose", "onCreateInCalendar"]),
    _createVNode(_component_SchedulerCalendarCalendarConfirmation, {
      modelValue: _ctx.isCalendarConfirmationModal,
      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (_ctx.isCalendarConfirmationModal = $event)),
      onClose: _ctx.cancelCalendarConfirmation,
      onConfirm: _cache[24] || (_cache[24] = ($event: any) => (_ctx.onCalendarConfirmationConfirm($event)))
    }, null, 8, ["modelValue", "onClose"]),
    _createVNode(_component_SchedulerTimingPlaceholderModal, {
      modelValue: _ctx.isPlaceholderTimingModalShown,
      "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => (_ctx.isPlaceholderTimingModalShown = $event)),
      "placeholder-id": _ctx.placeholderId,
      metadata: _ctx.eventMetadata,
      onSubmit: _ctx.submitPlaceholderForm
    }, null, 8, ["modelValue", "placeholder-id", "metadata", "onSubmit"]),
    (_ctx.isPlaceholderQuantityModalShown)
      ? (_openBlock(), _createBlock(_component_SchedulerQuantityPlaceholderModal, {
          key: 0,
          modelValue: _ctx.isPlaceholderQuantityModalShown,
          "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => (_ctx.isPlaceholderQuantityModalShown = $event)),
          "placeholder-id": _ctx.placeholderId,
          metadata: _ctx.eventMetadata,
          events: _ctx.events,
          onSubmit: _ctx.submitPlaceholderForm
        }, null, 8, ["modelValue", "placeholder-id", "metadata", "events", "onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}