import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "quantity-scheduler__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchedulerEvent = _resolveComponent("SchedulerEvent")
  const _component_AppEventActionModal = _resolveComponent("AppEventActionModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", {
      class: ["quantity-scheduler", { 'quantity-scheduler--disabled': _ctx.loading }]
    }, [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", {
          ref: "quantityEvents",
          class: "quantity-scheduler__events",
          onDragover: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.dragover && _ctx.dragover(...args)))
        }, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.events, (event) => {
            return (_openBlock(), _createBlock(_component_SchedulerEvent, {
              key: event.id,
              event: event,
              quantity: true,
              draggable: event.statusOrder < 3,
              class: "scheduler-event--quantity",
              "slot-height": _ctx.slotHeight,
              onDragstart: _ctx.dragstart,
              onDragend: _ctx.dragend,
              "onChangeEvent:duration": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change:eventDuration', $event))),
              "onChangePlaceholder:duration": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('change:placeholderDuration', $event))),
              onShowEvent: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('show-event', $event))),
              onDeletePlaceholder: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('delete-placeholder', $event)))
            }, null, 8, ["event", "draggable", "slot-height", "onDragstart", "onDragend"]))
          }), 128))
        ], 544),
        _createVNode("div", {
          class: "quantity-scheduler__add",
          onDragenter: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.addDragEnter && _ctx.addDragEnter(...args))),
          onDragover: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"])),
          onDrop: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.addDrop && _ctx.addDrop(...args))),
          onDragleave: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.addDragLeave && _ctx.addDragLeave(...args))),
          onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.addCalendarEvent && _ctx.addCalendarEvent(...args)))
        }, " + ", 32)
      ])
    ], 2),
    _createVNode(_component_AppEventActionModal, {
      modelValue: _ctx.modal,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.modal = $event)),
      onCreateNewPlaceholder: _ctx.createNewPlaceholder,
      onCreateNewEvent: _ctx.createNewEvent
    }, null, 8, ["modelValue", "onCreateNewPlaceholder", "onCreateNewEvent"])
  ], 64))
}