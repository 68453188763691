import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTextHighlighter = _resolveComponent("AppTextHighlighter")
  const _component_AppPhoneNumber = _resolveComponent("AppPhoneNumber")
  const _component_AppSpelledWord = _resolveComponent("AppSpelledWord")
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmTile = _resolveComponent("DmTile")

  return (_openBlock(), _createBlock(_component_DmTile, {
    bordered: "",
    rounded: "",
    padding: "md",
    class: _ctx.$bem({}),
    "data-test": "new-event-tab-returning-item"
  }, {
    default: _withCtx(() => [
      _createVNode("div", {
        class: _ctx.$bem({e: 'user-data'})
      }, [
        _createVNode("div", {
          class: _ctx.$bem({e: 'user-header'})
        }, [
          _createVNode("div", {
            class: _ctx.$bem({e: 'user-name'})
          }, [
            _createVNode(_component_AppTextHighlighter, {
              text: _ctx.clientName(_ctx.data.firstName, _ctx.data.lastName),
              pattern: _ctx.searchText
            }, null, 8, ["text", "pattern"])
          ], 2),
          _createVNode("div", null, [
            _createVNode(_component_AppPhoneNumber, {
              value: _ctx.data.phone,
              "highlight-pattern": _ctx.searchText
            }, null, 8, ["value", "highlight-pattern"])
          ])
        ], 2),
        _createVNode(_component_AppTextHighlighter, {
          text: _ctx.data.email,
          pattern: _ctx.searchText
        }, null, 8, ["text", "pattern"])
      ], 2),
      _createVNode("div", {
        class: _ctx.$bem({e: 'car-data'})
      }, [
        _createVNode("ul", {
          class: _ctx.$bem({e: 'cars'})
        }, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.data.cars, (car) => {
            return (_openBlock(), _createBlock("li", {
              key: car.id,
              class: [_ctx.$bem({e: 'car'}), "has-padding-sm"],
              "data-test": "new-event-tab-returning-item-car"
            }, [
              _createVNode("div", {
                class: _ctx.$bem({e: 'car-details'})
              }, [
                _createVNode("div", {
                  class: _ctx.$bem({e: 'car-name'}),
                  "data-test": "new-event-tab-returning-item-car-name"
                }, _toDisplayString(car.brand) + " " + _toDisplayString(car.model), 3),
                _createVNode(_component_AppSpelledWord, {
                  text: car.licensePlateNumber,
                  "highlight-pattern": _ctx.searchText,
                  class: _ctx.$bem({e: 'car-license-plate-number'})
                }, null, 8, ["text", "highlight-pattern", "class"])
              ], 2),
              _createVNode(_component_DmButton, {
                color: "primary",
                plain: "",
                rounded: "",
                class: _ctx.$bem({e: 'car-select'}),
                onClick: ($event: any) => (_ctx.selectCar(car))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.select')), 1)
                ]),
                _: 2
              }, 1032, ["class", "onClick"])
            ], 2))
          }), 128))
        ], 2),
        _createVNode(_component_DmButton, {
          plain: "",
          color: "primary",
          hoverable: "",
          block: "",
          class: _ctx.$bem({e: 'new-car'}),
          onClick: _ctx.addCar
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('newRepair.browser.newCar')), 1)
          ]),
          _: 1
        }, 8, ["class", "onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}