
import { defineComponent, PropType, inject } from 'vue';
import {
  DmButton,
  DmTile
} from '@dobrymechanik/vue-ui';
import {
  AppModal,
  AppModalHeader,
  AppModalBody,
  AppModalFooter,
  AppModalButton,
  AppEventForm
} from '@/components';
import SchedulerNewEventModalBrowser from './Browser';
import SchedulerNewEventSimpleForm from './SimpleForm';
import { CreateRepairReturningClient, CreateRepairFullData, CreateRepairPartialData, CreateRepairPayload, CreateRepairType, EventFormData, EventMetadata, CreateRepairReturningClientWithNewCar } from '@/models/events';
import { mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import {
  URI_REPAIR_CREATE
} from '@/api/endpoints';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';

enum Tab {
  NEW_CLIENT,
  RETURNING_CLIENT
}

const gtmType = (inCalendar: boolean) => inCalendar ? 'In calendar' : 'Unplanned';
const gtmMode = (isFull: boolean) => isFull ? 'full' : 'mini';
const gtmAction = (isNew: boolean) => isNew ? 'Added new client and visit' : 'Added returning visit';
const initialValues: EventFormData = {
  name: null,
  phone: null,
  email: null,
  price: null,
  notes: null,
  brand: {
    id: null,
    name: ''
  },
  model: {
    id: null,
    name: ''
  },
  reason: null,
  licensePlateNumber: null,
  vin: null,
  marketingConsent: false
};

export default defineComponent({
  name: 'SchedulerNewEventModal',
  components: {
    AppModal,
    AppModalHeader,
    AppModalBody,
    AppModalFooter,
    AppModalButton,
    DmTile,
    AppEventForm,
    DmButton,
    SchedulerNewEventModalBrowser,
    SchedulerNewEventSimpleForm
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    metadata: {
      type: Object as PropType<EventMetadata | null>,
      default: null
    },
    hourToSelect: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'close',
    'create-in-calendar',
    'create-unplanned',
    'start-hour-changed'
  ],
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data: function () {
    return {
      loading: false as boolean,
      tabItems: [
        {
          key: 'new',
          text: this.$t('newRepair.new')
        },
        {
          key: 'returning',
          text: this.$t('newRepair.returning')
        }
      ],
      isAsyncCheckModalShown: false as boolean,
      activeTabIndex: 0 as number,
      uriCreate: URI_REPAIR_CREATE as string,
      asyncCheckValue: '' as string,
      isFormExtended: false as boolean,
      initialValues,
      start: '08:00:00' as string
    };
  },
  computed: {
    title (): string {
      return this.metadata?.workspace || this.$t('repair.new');
    },
    repairDatetime (): string {
      const startStr = this.metadata?.start;
      if (startStr) {
        const start = new Date(startStr);
        const end = new Date(start.getTime() + 45 * 60 * 1000);
        const startDatetime = start.toLocaleTimeString(this.$i18n.locale, {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
        const endHour = end.toLocaleTimeString(this.$i18n.locale, {
          hour: '2-digit',
          minute: '2-digit'
        });
        return `${startDatetime} - ${endHour}`;
      }
      return '';
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler (val) {
        if (val) {
          this.activeTabIndex = 0;
          this.isFormExtended = false;
        }
      }
    },
    start (val) {
      this.$emit('start-hour-changed', val);
    }
  },
  mounted () {
    window.addEventListener('keydown', this.getInitialValuesFromClipboard);
  },
  onmounted () {
    window.removeEventListener('keydown', this.getInitialValuesFromClipboard);
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    clearClipboardField (field: string): string {
      // eslint-disable-next-line no-useless-escape
      return field.replace(/^[\.|\,|\s]*|[\.|\,|\s]*$/g, '').replace(/<br\s*[\/]?>/g, '\n');
    },
    async getInitialValuesFromClipboard (e: any) {
      e = e || window.event;
      const key = e.which || e.keyCode;
      const ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17));

      if (key === 86 && ctrl) {
        navigator.clipboard.readText()
          .then(text => {
            try {
              const data = JSON.parse(text);
              this.initialValues.reason = this.clearClipboardField(data.content);
              const phone = this.clearClipboardField(data.phone)[0] === '+' ? this.clearClipboardField(data.phone) : `+48${this.clearClipboardField(data.phone)}`;
              (this.$refs.SchedulerNewEventSimpleForm as any).setInitialPhoneNumber(phone);
              this.initialValues.phone = this.clearClipboardField(phone);
              this.initialValues.name = this.clearClipboardField(data.name);
              this.initialValues.email = this.clearClipboardField(data.email);
              this.initialValues.brand.name = this.clearClipboardField(data.brand);
              this.initialValues.model.name = this.clearClipboardField(data.model);
              this.initialValues.licensePlateNumber = this.clearClipboardField(data.plate_number);
              this.initialValues.vin = this.clearClipboardField(data.vin);
            } catch {
            }
          });
      }
    },
    close (): void {
      this.initialValues = initialValues;
      this.$emit('close');
    },

    createNewEventPayload (values: any, createRepairType: CreateRepairType): any {
      switch (createRepairType) {
      case CreateRepairType.CreateByFullData: {
        const fullDataPayload: CreateRepairFullData = {
          client: {
            firstName: values.clientName ? values.clientName.split(' ')[0] : '',
            lastName: values.clientName ? values.clientName.split(' ')[1] : '',
            phoneNumber: values.clientPhone,
            email: values.clientEmail || null,
            marketingConsent: !!values.clientMarketingConsent
          },
          car: {
            specification: {
              internal: {
                brand: {
                  id: values.carBrandId || null,
                  text: values.carBrand
                },
                model: {
                  id: values.carModelId || null,
                  text: values.carModel
                }
              },
              tecdoc: {
                brand: values.carTecDocBrandId ? {
                  id: values.carTecDocBrandId,
                  text: values.carTecDocBrand
                } : null,
                model: values.carTecDocModelId ? {
                  id: values.carTecDocModelId,
                  text: values.carTecDocModel
                } : null,
                type: values.carTecDocTypeId ? {
                  id: values.carTecDocTypeId,
                  text: values.carTecDocType
                } : null
              }
            },
            licensePlateNumber: values.carLicensePlateNumber || null,
            vin: values.carVin || null,
            year: values.carYear || null
          },
          repair: {
            reason: {
              id: values.repairReasonId || null,
              text: values.repairReason
            },
            notes: values.repairNotes || null,
            price: values.repairPrice ? Math.round(values.repairPrice) : null,
            workspaceId: this.metadata?.workspaceId || null,
            plannedRepairAt: this.metadata?.startStr || null
          }
        };
        return fullDataPayload;
      }
      case CreateRepairType.CreateByPartialData: {
        const partialDataPayload: CreateRepairPartialData = {
          client: {
            phoneNumber: values.phone
          },
          repair: {
            workspaceId: this.metadata?.workspaceId || null,
            plannedRepairAt: this.metadata?.startStr || null,
            reason: {
              id: values.reasonId || null,
              text: values.reason
            }
          }
        };
        return partialDataPayload;
      }
      case CreateRepairType.CreateByReturningClient: {
        const returningClientPayload: CreateRepairReturningClient = {
          client: {
            id: values.clientId,
            marketingConsent: !!values.marketingConsent
          },
          car: {
            id: values.carId
          },
          repair: {
            reason: {
              id: values.reasonId || null,
              text: values.reason
            },
            notes: values.notes || null,
            price: values.price ? Math.round(values.price * 100) : null,
            workspaceId: this.metadata?.workspaceId || null,
            plannedRepairAt: this.metadata?.startStr || null
          }
        };
        return returningClientPayload;
      }
      case CreateRepairType.CreateByReturningClientWithNewCar: {
        const returningClientPayload: CreateRepairReturningClientWithNewCar = {
          client: {
            id: values.clientId,
            marketingConsent: !!values.marketingConsent
          },
          car: {
            specification: {
              internal: {
                brand: {
                  id: values.brandId || null,
                  text: values.brand
                },
                model: {
                  id: values.modelId || null,
                  text: values.model
                }
              },
              tecdoc: {
                brand: null,
                model: null,
                type: null
              }
            },
            licensePlateNumber: values.licensePlateNumber || null,
            vin: values.vin || null,
            year: values.year || null
          },
          repair: {
            reason: {
              id: values.reasonId || null,
              text: values.reason
            },
            notes: values.notes || null,
            price: values.price ? Math.round(values.price * 100) : null,
            workspaceId: this.metadata?.workspaceId || null,
            plannedRepairAt: this.metadata?.startStr || null
          }
        };
        return returningClientPayload;
      }
      }
    },

    async submit (values: any, uri: string, isNewClient: boolean, createRepairType: CreateRepairType): Promise<void> {
      const payload: CreateRepairPayload = {
        type: createRepairType,
        payload: this.createNewEventPayload(values, createRepairType)
      };
      this.loading = true;

      try {
        await this.$http.post(uri, payload);
        this.$emit(this.metadata ? 'create-in-calendar' : 'create-unplanned');
        this.trackEvent({
          event: TrackedEventType.SUCCESS,
          category: TrackedEventCategory.VISIT,
          action: gtmAction(isNewClient),
          label: `${gtmType(!!this.metadata)} ${gtmMode(this.isFormExtended || !isNewClient)} form`
        });
        if (values.vin) {
          this.trackEvent({
            event: TrackedEventType.CLICK,
            category: TrackedEventCategory.VISIT,
            action: 'VIN filled'
          });
        }
        this.close();
      } catch (e) {
        this.createNotification({
          message: this.$t('newRepair.addError'),
          type: 'error'
        });
        this.trackEvent({
          event: TrackedEventType.ERROR,
          category: TrackedEventCategory.VISIT,
          action: gtmAction(isNewClient),
          label: `${gtmType(!!this.metadata)} ${gtmMode(this.isFormExtended)} form`
        });
      }
      this.loading = false;
    },
    onTabChange (tabIndex: number): void {
      if (tabIndex === Tab.RETURNING_CLIENT) {
        setTimeout(() => {
          this.$emitter.emit('open-returning-tab');
        });
      }
    },
    selectTab (tabIndex: number): void {
      this.activeTabIndex = tabIndex;
      if (tabIndex === Tab.RETURNING_CLIENT) {
        setTimeout(() => {
          this.$emitter.emit('open-returning-tab');
        });
      }
    },
    openAsyncCheckModal (field: { name: string; value: string }): void {
      this.isAsyncCheckModalShown = true;
      this.asyncCheckValue = field.value;
      this.trackEvent({
        event: TrackedEventType.SHOW,
        category: TrackedEventCategory.VISIT,
        action: 'Returning client detected'
      });
    },
    searchQueryInBrowser (): void {
      this.isAsyncCheckModalShown = false;
      this.activeTabIndex = 1;
      this.$emitter.emit('open-returning-tab', this.asyncCheckValue);
    },
    toggleFormMode (values: any): void {
      this.initialValues = {
        ...initialValues,
        ...values
      };
      this.isFormExtended = !this.isFormExtended;
    },
    onSubmitClick (isNewClient: boolean): void {
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.VISIT,
        action: gtmAction(isNewClient),
        label: `${gtmType(!!this.metadata)} ${gtmMode(this.isFormExtended || !isNewClient)} form`
      });
    }
  }
});
