
import { defineComponent, PropType } from 'vue';
import SchedulerEvent from '../SchedulerEvent/Component.vue';
import {
  AppEventActionModal
} from '@/components';
import { WorkspaceExternalConfigQuantitative } from '@/api/models';

export default defineComponent({
  name: 'QuantityScheduler',
  components: {
    SchedulerEvent,
    AppEventActionModal
  },
  props: {
    events: {
      type: Array as PropType<any[]>,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    draggedEvent: {
      type: Object as PropType<Node>,
      default: null
    },
    slotHeight: {
      type: Number,
      required: true
    },
    resourceExternalConfig: {
      type: Object as PropType<WorkspaceExternalConfigQuantitative>,
      default: null
    },
    currentDay: {
      type: Object as PropType<Date>,
      required: true
    }
  },
  emits: [
    'change:eventDate',
    'update:draggedEvent',
    'change:eventDuration',
    'show-event',
    'create-new-event',
    'reorder-events',
    'change:placeholderDuration',
    'create-new-placeholder',
    'delete-placeholder'
  ],
  data () {
    return {
      show: false as boolean,
      container: null as unknown as HTMLElement,
      modal: false
    };
  },
  computed: {
    isAbleToAddPlaceholder (): boolean {
      if (!this.resourceExternalConfig) return false;
      if (!this.resourceExternalConfig.admissionHours.find((ah: any) => ah.day === this.currentDay.getDay())) return false;
      return ((this.resourceExternalConfig.capacity as number - this.events.length) > 0);
    }
  },
  methods: {
    addCalendarEvent (slot: any) {
      if (this.resourceExternalConfig && this.isAbleToAddPlaceholder) {
        this.modal = true;
      } else {
        this.createNewEvent();
      }
    },
    createNewEvent () {
      this.modal = false;
      this.$emit('create-new-event');
    },
    createNewPlaceholder () {
      this.modal = false;
      this.$emit('create-new-placeholder');
    },
    slots (d: Date, startHour: number, endHour: number, duration: number) {
      const start = d.setHours(startHour, 0, 0);
      const end = d.setHours(endHour, 0, 0);
      const arr = [];
      let last = 0;
      let item = 0;
      while (last < end) {
        last = start + item * duration * 60 * 1000;
        arr.push(last);
        item = item + 1;
      }

      return arr.map(item => new Date(item));
    },
    parseData (slot: any) {
      return Date.parse(slot);
    },
    getRowLabel (slot: any) {
      const hour = slot.getHours().toString().length === 1 ? `0${slot.getHours()}` : slot.getHours();
      const minutes = slot.getMinutes().toString().length === 1 ? `0${slot.getMinutes()}` : slot.getMinutes();
      return `${hour}:${minutes}`;
    },
    findSlot (slot: any) {
      return this.events.find((event: any) => {
        return Date.parse(event.start) === Date.parse(slot);
      });
    },
    dragleave (e: Event) {
      (e.target as HTMLElement).classList.remove('quantity-scheduler__add--dragover');
    },
    dragover (e: DragEvent) {
      e.preventDefault();

      const afterElement = this.getDragAfterElement(this.container, e.clientY);
      const draggable = document.querySelector('.dragging');
      if (afterElement == null && !!draggable) {
        this.container.appendChild(draggable);
      } else if (draggable) {
        this.container.insertBefore(draggable, afterElement);
      }
    },
    getDragAfterElement (container: HTMLElement, y: number) {
      if (!container) return;
      const draggableElements = [...container.querySelectorAll('.scheduler-event--quantity:not(.dragging)')];
      return draggableElements.reduce((closest, child) => {
        const box = child.getBoundingClientRect();
        const offset = y - box.top - box.height / 2;
        if (offset < 0 && offset > closest.offset) {
          return { offset: offset, element: child };
        } else {
          return closest;
        }
      }, ({ offset: Number.NEGATIVE_INFINITY }) as any).element;
    },
    dragstart (e: Event) {
      this.$emit('update:draggedEvent', (e.target as HTMLElement));

      this.container = ((e.target as HTMLElement).parentElement as HTMLElement);

      (e.target as HTMLElement).classList.add('dragging');
      setTimeout(() => ((e.target as HTMLElement).style.opacity = '0'), 0);
    },
    dragend (e: Event) {
      (e.target as HTMLElement).classList.remove('dragging');
      (e.target as HTMLElement).style.opacity = '1';
      const wrapper = this.$refs.quantityEvents;

      if (wrapper) {
        const events = (wrapper as HTMLElement).children;
        const eventsIds = [...events].map(event => event.id);
        this.$emit('reorder-events', eventsIds);
      }

      this.$emit('update:draggedEvent', null);
    },
    addDragEnter (e: Event) {
      if (this.eventBelongToSheduler()) return;
      if (this.draggedEvent) { (e.target as HTMLElement).classList.add('quantity-scheduler__add--dragover'); }
    },
    addDrop (e: Event) {
      if (this.eventBelongToSheduler()) return;
      (e.target as HTMLElement).classList.remove('quantity-scheduler__add--dragover');

      // append dragged element to slot
      if (this.draggedEvent) {
        (this.draggedEvent as HTMLElement).classList.add('scheduler-event--quantity');
        ((e.target as HTMLElement).parentElement as HTMLElement).insertBefore(this.draggedEvent, (e.target as HTMLElement));
      }

      this.$emit('change:eventDate');
      window.scrollTo(0, 0);
    },
    addDragLeave (e: Event) {
      (e.target as HTMLElement).classList.remove('quantity-scheduler__add--dragover');
    },
    eventBelongToSheduler (): boolean {
      if (!this.draggedEvent) return false;
      return !!this.events.find(event => event.id === (this.draggedEvent as HTMLElement).getAttribute('id'));
    }
  }
});
