
import { defineComponent, inject } from 'vue';
import { SchedulerQuantityPlaceholderModal, SchedulerTimingPlaceholderModal, SchedulerNavigation, SchedulerNewEventModal, SchedulerMonthlyView, SchedulerWeeklyView, SchedulerListView, SchedulerDailyView } from './_components';
import { jsonToURLSearchParams } from '@/helpers/requests';
import {
  URI_CALENDAR_RESOURCES,
  URI_CALENDAR_EVENTS,
  URI_REPAIR,
  URI_REORDER_EVENTS,
  URI_REPAIR_BLOCK_PLACEHOLDER_UPDATE,
  URI_REPAIR_BLOCK_PLACEHOLDER_CREATE
} from '@/api/endpoints';
import {
  CalendarResource,
  CalendarEvent,
  CalendarReorderedRepairs,
  CalendarEventDetails
} from '@/api/models';
import dayjs from 'dayjs';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';
import {
  AppEventModal
} from '@/components';
import { EventMetadata } from '@/models/events';
import SchedulerCalendarCalendarConfirmation from '@/views/Scheduler/_components/CalendarConfirmation/Component.vue';
import { mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import { filterAndSortEvents } from '@/views/Scheduler/helpers';
import { debounce } from 'debounce';
import { ChangeDateAndWorkspacePayload } from '@/components/AppEventModal/ChangeEventDateModal/Component.vue';

interface EventDataPayload {
  plannedRepairAt: string | null;
  workspaceId: string | null;
  sendConfirmation: number | null;
  duration: number | null;
}
interface EventData {
  payload: EventDataPayload;
  eventId: string;
}

export default defineComponent({
  name: 'Scheduler',
  components: {
    SchedulerWeeklyView,
    SchedulerMonthlyView,
    SchedulerListView,
    SchedulerDailyView,
    SchedulerCalendarCalendarConfirmation,
    AppEventModal,
    SchedulerNavigation,
    SchedulerNewEventModal,
    SchedulerTimingPlaceholderModal,
    SchedulerQuantityPlaceholderModal
  },
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      currentDay: new Date() as Date,
      events: [] as CalendarEvent[],
      componentKey: 0 as number,
      loading: false,
      resources: [] as CalendarResource[],
      draggedEvent: null as null | HTMLElement,
      slotHeight: 45 as number,
      scrolling: false as boolean,
      scrollingDelay: 20 as number,
      scrollingOffset: 10 as number,
      isEventModalActive: false as boolean,
      eventId: null as string | null,
      placeholderId: null as string | null,
      isNewEventModalShown: false as boolean,
      isPlaceholderTimingModalShown: false as boolean,
      isPlaceholderQuantityModalShown: false as boolean,
      eventMetadata: null as null | EventMetadata,
      modalWithHours: false as boolean,
      isCalendarConfirmationModal: false as boolean,
      eventData: null as null | EventData,
      reordering: false as boolean,
      viewType: 'day' as string,
      viewTypes: [
        'day',
        'week',
        'month',
        'list'
      ]
    };
  },
  watch: {
    currentDay: {
      handler () {
        this.events = [];
        this.loading = true;
        this.debounceFetchEvents();
      },
      deep: true
    }
  },
  async created () {
    this.getInitialEvent();
    await this.fetchResources();
    this.debounceFetchEvents = debounce(this.debounceFetchEvents, 500);
    this.scrollTo(1260);
  },
  methods: {
    scrollTo (yScroll: number) {
      window.scroll(0, yScroll);
    },
    debounceFetchEvents () {
      this.fetchEvents();
    },
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    async fetchResources (): Promise<void> {
      const res = (await this.$http.get<CalendarResource[]>(URI_CALENDAR_RESOURCES)).data;
      this.resources = res;
    },
    async fetchEvents () {
      const tryAgainButtonWrapper = document.getElementById('scheduler__try-again-button');
      if (tryAgainButtonWrapper) tryAgainButtonWrapper.remove();

      this.loading = true;

      let params = null;

      switch (this.viewType) {
      case 'day':
        params = {
          start: dayjs(this.currentDay).hour(0).minute(0).second(0).format(),
          end: dayjs(this.currentDay).add(1, 'day').hour(0).minute(0).second(0).format()
        };
        break;
      case 'week':
        params = {
          start: dayjs(this.currentDay).subtract(this.currentDay.getDay() ? this.currentDay.getDay() - 1 : 6, 'day').hour(0).minute(0).second(0).format(),
          end: dayjs(this.currentDay).add(this.currentDay.getDay() ? 8 - this.currentDay.getDay() : 1, 'day').hour(0).minute(0).second(0).format()
        };
        break;
      case 'month':
      case 'list':
        params = {
          start: dayjs(this.currentDay).startOf('month').hour(0).minute(0).second(0).format(),
          end: dayjs(this.currentDay).endOf('month').add(1, 'day').hour(0).minute(0).second(0).format()
        };
        break;
      }

      try {
        const response = await this.$http.get<CalendarEvent[]>(URI_CALENDAR_EVENTS, { params });
        this.events = response.data;
        this.componentKey += 1;
      } catch (e) {
        const tryAgainButtonWrapper = document.createElement('div');
        tryAgainButtonWrapper.setAttribute('id', 'scheduler__try-again-button');
        const schedulerEl = document.getElementById('scheduler');
        if (schedulerEl) {
          schedulerEl.appendChild(tryAgainButtonWrapper);
          const tryAgainButton = document.createElement('button');
          tryAgainButton.innerHTML = this.$t('common.tryAgain');
          tryAgainButtonWrapper.appendChild(tryAgainButton);
          tryAgainButton.onclick = () => this.fetchEvents();
        }
      } finally {
        if (this.reordering) this.reordering = false;
        this.loading = false;
      }
    },
    async changeEventDate (reorder?: boolean) {
      this.loading = true;
      if (reorder) this.reordering = true;
      if (!this.eventData) return;

      const payload = jsonToURLSearchParams(this.eventData.payload);

      try {
        await this.$http.patch(URI_REPAIR(this.eventData.eventId), payload);
      } catch (e) {
        this.createNotification({
          message: this.$t('calendar.event.moveError'),
          type: 'error'
        });
      } finally {
        if (reorder && this.eventData.payload.workspaceId) {
          const arr = this.filterEvents(this.eventData.payload.workspaceId, true).map(e => e.id);
          arr.push(this.eventData.eventId);

          await this.reorderEvents(arr, this.eventData.payload.workspaceId);
        }
        this.fetchEvents();
        this.loading = false;
        this.updateDraggedEvent(null);
        this.eventData = null;
      }
    },
    async changeEventDateTiming (data: any, workspaceId: string) {
      this.isCalendarConfirmationModal = true;

      this.eventData = {
        payload: {
          plannedRepairAt: dayjs(data.plannedRepairAt).format(),
          workspaceId: workspaceId,
          sendConfirmation: data.sendConfirmation,
          duration: data.duration
        },
        eventId: this.draggedEvent?.id || ''
      };
    },
    async changeEventDateQuantity (workspaceId: string) {
      this.eventData = {
        payload: {
          plannedRepairAt: null,
          workspaceId: workspaceId,
          sendConfirmation: null,
          duration: null
        },
        eventId: this.draggedEvent?.id || ''
      };

      await this.changeEventDate(true);
    },
    async changeEventDateModal (data: ChangeDateAndWorkspacePayload, eventId: string) {
      this.isCalendarConfirmationModal = true;

      this.eventData = {
        payload: {
          plannedRepairAt: data.date,
          workspaceId: data.workspace,
          sendConfirmation: null,
          duration: null
        },
        eventId: eventId
      };
    },
    async onCalendarConfirmationConfirm (sendConfirmation: boolean): Promise<void> {
      if (this.eventData) {
        this.eventData.payload.sendConfirmation = sendConfirmation ? 1 : 0;
      }

      this.isCalendarConfirmationModal = false;
      await this.changeEventDate();
    },
    async changeEventDuration (data: any, workspaceId: string) {
      this.loading = true;

      const payload = jsonToURLSearchParams({
        duration: data.duration,
        workspaceId
      });

      try {
        await this.$http.patch(URI_REPAIR(data.eventId), payload);
      } catch (e) {
        this.createNotification({
          message: this.$t('calendar.event.durationChangeError'),
          type: 'error'
        });
      } finally {
        this.fetchEvents();
        this.loading = false;
      }
    },
    async changePlaceholderDuration (data: any) {
      this.loading = true;

      const payload = {
        duration: data.duration,
        workspaceId: data.resourceId,
        reason: data.reason,
        fromAt: data.start
      };

      try {
        await this.$http.patch(URI_REPAIR_BLOCK_PLACEHOLDER_UPDATE(data.id), payload);
      } catch (e) {
        this.createNotification({
          message: this.$t('calendar.event.durationChangeError'),
          type: 'error'
        });
      } finally {
        await this.fetchEvents();
        this.loading = false;
      }
    },
    async deletePlaceholder (id: string) {
      this.loading = true;

      try {
        await this.$http.delete(URI_REPAIR_BLOCK_PLACEHOLDER_UPDATE(id));
      } catch (e) {
        this.createNotification({
          message: this.$t('calendar.event.moveError'),
          type: 'error'
        });
      } finally {
        await this.fetchEvents();
        this.loading = false;
      }
    },
    async submitPlaceholderForm (payload: any) {
      try {
        await this.$http.post(URI_REPAIR_BLOCK_PLACEHOLDER_CREATE, payload);
      } catch (e) {
        this.createNotification({
          message: this.$t('calendar.event.moveFromPendingError'),
          type: 'error'
        });
      } finally {
        this.isPlaceholderTimingModalShown = false;
        this.isPlaceholderQuantityModalShown = false;
        this.fetchEvents();
        this.loading = false;
      }
    },
    filterEvents (resourceId: string, quantitative: boolean) {
      return filterAndSortEvents(this.events, resourceId, quantitative);
    },
    updateDraggedEvent (event: null | HTMLElement) {
      this.draggedEvent = event;
    },
    showEvent (id: string, isInCalendar: boolean | null): void {
      this.eventId = id;
      this.isEventModalActive = true;
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.VISIT,
        action: 'Visit details seen',
        label: isInCalendar !== null ? (isInCalendar ? 'In calendar' : 'Unplanned') : null
      });
    },
    closeEvent (): void {
      this.eventId = null;
      this.isEventModalActive = false;
      const routeQuery = {
        ...this.$route.query
      };
      delete routeQuery.event;
      this.$router.replace({
        query: routeQuery
      });
    },
    closeNewEventModal (): void {
      this.isNewEventModalShown = false;
    },
    showNewEventModal (resource: CalendarResource, start: Date | undefined): void {
      this.modalWithHours = !start;
      const startDate = start || new Date(this.currentDay.setHours(8, 0, 0));

      const details: EventMetadata = {
        start: startDate,
        startStr: dayjs(startDate).format(),
        workspace: resource.title,
        workspaceId: resource.id
      };
      this.eventMetadata = details;
      this.isNewEventModalShown = true;
    },
    showNewPlaceholderModalTiming (resource: CalendarResource, start: Date | undefined): void {
      const startDate = start || new Date(this.currentDay.setHours(8, 0, 0));

      const details: EventMetadata = {
        start: startDate,
        startStr: dayjs(startDate).format(),
        workspace: resource.title,
        workspaceId: resource.id
      };
      this.eventMetadata = details;

      this.isPlaceholderTimingModalShown = true;
    },
    showNewPlaceholderModalQuantity (resource: CalendarResource): void {
      const startDate = new Date(this.currentDay.setHours(8, 0, 0));

      const details: EventMetadata = {
        ...resource,
        start: startDate,
        startStr: dayjs(startDate).format(),
        workspace: resource.title,
        workspaceId: resource.id
      };
      this.eventMetadata = details;

      this.isPlaceholderQuantityModalShown = true;
    },

    showNewPlaceholderModal (resource: CalendarResource, start: Date | undefined): void {
      if (start) {
        this.showNewPlaceholderModalTiming(resource, start);
      } else {
        this.showNewPlaceholderModalQuantity(resource);
      }
    },

    startHourChanged (startStr: string) {
      const splittedHour: string[] = startStr.split(':');
      const startDate = new Date(this.currentDay.setHours(parseInt(splittedHour[0]), parseInt(splittedHour[1]), parseInt(splittedHour[2])));

      this.eventMetadata = {
        start: startDate,
        startStr: dayjs(startDate).format(),
        workspace: this.eventMetadata?.workspace || '',
        workspaceId: this.eventMetadata?.workspaceId || ''
      };
    },
    async reorderEvents (events: string[], resourceId: string) {
      this.loading = true;
      const data: CalendarReorderedRepairs = {
        reorderedRepairsIds: events
      };
      try {
        await this.$http.post<any>(URI_REORDER_EVENTS(resourceId), data);
      } catch (e) {
        this.createNotification({
          message: this.$t('calendar.event.reorderError'),
          type: 'error'
        });
      } finally {
        if (!this.reordering && !this.isCalendarConfirmationModal) {
          this.fetchEvents();
          this.loading = false;
        }
      }
    },
    getInitialEvent (): void {
      const eventId = this.$route.query.event as string | null;
      if (eventId && eventId !== 'null') {
        this.showEvent(eventId, null);
      } else {
        this.fetchEvents();
      }
    },
    onEventFetch (event: CalendarEventDetails): void {
      if (this.$route.query.event) {
        this.currentDay = new Date(event.plannedRepairAt);
      }
    },
    changeViewType (viewType: string) {
      this.events = [];
      this.viewType = viewType;
      this.fetchEvents();
      setTimeout(() => this.scrollTo(this.viewType === 'day' ? 1260 : 0), 0);
    },
    setDayViewAndSetDate (date: string) {
      if (this.viewType !== 'day') {
        this.viewType = 'day';
      }
      this.currentDay = new Date(date);
    },
    cancelCalendarConfirmation () {
      this.fetchEvents();
      this.isCalendarConfirmationModal = false;
    }
  }
});
