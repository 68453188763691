import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "scheduler-navigation" }
const _hoisted_2 = { class: "scheduler-navigation__view" }
const _hoisted_3 = { class: "scheduler-navigation__label" }
const _hoisted_4 = { class: "scheduler-navigation__view-types" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmIconButton = _resolveComponent("DmIconButton")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DmButton, {
        color: "primary",
        dark: "",
        rounded: "",
        class: "has-margin-right-md",
        onClick: _ctx.setToday
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('calendar.buttons.today')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_DmIconButton, {
        icon: "chevron-left",
        hoverable: "",
        light: "",
        rounded: "",
        flat: "",
        bordered: "",
        onClick: _ctx.previousDay
      }, null, 8, ["onClick"]),
      _createVNode(_component_DmIconButton, {
        icon: "chevron-right",
        hoverable: "",
        light: "",
        rounded: "",
        flat: "",
        bordered: "",
        class: "has-margin-right-md",
        onClick: _ctx.nextDay
      }, null, 8, ["onClick"]),
      _createVNode("div", _hoisted_3, _toDisplayString(_ctx.getDateString), 1)
    ]),
    _createVNode("div", _hoisted_4, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.viewTypes, (view) => {
        return (_openBlock(), _createBlock("div", {
          key: `view-type:${view}`,
          tabindex: "0",
          class: ["scheduler-navigation__view-type", { 'scheduler-navigation__view-type--selected': _ctx.viewType === view }],
          onClick: ($event: any) => (_ctx.$emit('update:view-type', view)),
          onKeyup: _withKeys(($event: any) => (_ctx.$emit('update:view-type', view)), ["enter"])
        }, _toDisplayString(_ctx.$t(`calendar.buttons.${view}`)), 43, ["onClick", "onKeyup"]))
      }), 128))
    ])
  ]))
}