
import { PropType, defineComponent } from 'vue';
import {
  DmInput,
  DmAsyncContent,
  DmButton
} from '@dobrymechanik/vue-ui';
import SchedulerNewEventModalBrowserItem from './Item';
import { URI_SEARCH } from '@/api/endpoints';
import {
  Client,
  ClientCar
} from '@/api/models';
import {
  AppModalHeader,
  AppModalBody,
  AppNewEventForm
} from '@/components';
import { EventFormData } from '@/models/events';

export default defineComponent({
  name: 'SchedulerNewEventModalBrowser',
  components: {
    DmInput,
    DmAsyncContent,
    DmButton,
    AppModalHeader,
    AppModalBody,
    SchedulerNewEventModalBrowserItem,
    AppNewEventForm
  },
  props: {
    initialValues: {
      type: Object as PropType<EventFormData>,
      required: true
    }
  },
  emits: [
    'add-new-client',
    'submit',
    'click-submit',
    'submit-with-new-car'
  ],
  data () {
    return {
      isIntroShown: true as boolean,
      searchText: '' as string,
      timeout: undefined as number | undefined,
      loading: false as boolean,
      error: false as boolean,
      items: [] as Client[],
      isFormOpen: false as boolean,
      selectedClient: null as Client | null,
      selectedCar: null as ClientCar | null,
      typing: false as boolean
    };
  },
  computed: {
    highlightText (): string {
      return (this.searchText.length > 2 && !this.typing) ? this.searchText : '';
    }
  },
  created (): void {
    this.$emitter.on('open-returning-tab', (query) => this.onOpen(query));
  },
  methods: {
    onOpen (query?: string): void {
      this.focusOnSearchInput();
      if (query) {
        this.searchText = query;
        this.search(this.searchText);
      }
    },
    focusOnSearchInput (): void {
      const searchInput = this.$refs.searchInput as HTMLInputElement;
      if (searchInput) searchInput.focus();
    },
    onSearchTextInput (searchText: string): void {
      this.typing = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.search(searchText);
      }, 500);
    },
    async search (searchText: string): Promise<void> {
      if (searchText.length < 3) {
        this.isIntroShown = true;
      } else {
        this.isIntroShown = false;
        this.loading = true;
        this.error = false;
        this.typing = false;
        try {
          this.items = (await this.$http.get<{ results: Client[] }>(URI_SEARCH(searchText))).data.results;
        } catch (e) {
          this.error = true;
        }
        this.loading = false;
      }
    },
    addNewClient (): void {
      this.$emit('add-new-client');
    },
    cancelSearchResults (): void {
      this.isIntroShown = true;
      this.searchText = '';
      this.focusOnSearchInput();
    },
    selectClient (e: { client: Client; car: ClientCar | null }): void {
      this.selectedClient = e.client;
      this.selectedCar = e.car;
      this.isFormOpen = true;
    },
    onSubmitClick (): void {
      this.$emit('click-submit');
    },
    submit (values: EventFormData): void {
      if (values.carId) {
        this.$emit('submit', values);
      } else {
        this.$emit('submit-with-new-car', values);
      }
    }
  }
});
