
import { defineComponent, PropType } from 'vue';
import {
  DmIconButton,
  DmButton
} from '@dobrymechanik/vue-ui';
import { getStringDay, getStringMonth } from '@/views/Scheduler/helpers';

export default defineComponent({
  name: 'SchedulerNavigation',
  components: { DmButton, DmIconButton },
  props: {
    modelValue: {
      type: Object as PropType<Date>,
      required: true
    },
    viewType: {
      type: String as PropType<string>,
      required: true
    },
    viewTypes: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  emits: ['update:modelValue', 'update:view-type'],
  computed: {
    getDateString (): string {
      const dayOfWeek = this.modelValue.getDay();
      const from = new Date(this.modelValue);
      const weeklyViewFrom = new Date(from.setDate(from.getDate() - (dayOfWeek ? dayOfWeek - 1 : 6)));
      const to = new Date(this.modelValue);
      const weeklyViewTo = new Date(to.setDate(to.getDate() + (dayOfWeek ? 7 - dayOfWeek : 0)));

      switch (this.viewType) {
      case 'day':
        return this.generateFullDayDesc(this.modelValue);
      case 'week':
        return this.generateWeeklyDesc(weeklyViewFrom, weeklyViewTo);
      case 'month':
      case 'list':
        return this.generateMonthlyDesc(this.modelValue);
      default:
        return '';
      }
    }
  },
  methods: {
    getMonthString (date: Date): string {
      return this.getStringMonth(date.getMonth());
    },
    generateFullDayDesc (date: Date): string {
      const stringDay = this.getStringDay(date.getDay());
      const dayDescription = `${stringDay}, ${date.getDate()}`;
      return `${dayDescription} ${this.getMonthString(date)} ${date.getFullYear()}`;
    },
    generateMonthlyDesc (date: Date): string {
      return `${this.getMonthString(date)} ${date.getFullYear()}`;
    },
    generateWeeklyDesc (from: Date, to: Date): string {
      const fromStr = `${from.getDate()} ${this.getMonthString(from)} ${from.getFullYear()}`;
      const toStr = `${to.getDate()} ${this.getMonthString(to)} ${to.getFullYear()}`;
      return `${fromStr} - ${toStr}`;
    },
    setToday () {
      this.$emit('update:modelValue', new Date());
    },
    nextDay () {
      if (this.viewType === 'day') {
        this.$emit('update:modelValue', new Date(this.modelValue.setDate(this.modelValue.getDate() + 1)));
      } else if (this.viewType === 'month' || this.viewType === 'list') {
        this.$emit('update:modelValue', new Date(this.modelValue.setMonth(this.modelValue.getMonth() + 1)));
      } else if (this.viewType === 'week') {
        this.$emit('update:modelValue', new Date(this.modelValue.setDate(this.modelValue.getDate() + 7)));
      }
    },
    previousDay () {
      if (this.viewType === 'day') {
        this.$emit('update:modelValue', new Date(this.modelValue.setDate(this.modelValue.getDate() - 1)));
      } else if (this.viewType === 'month' || this.viewType === 'list') {
        this.$emit('update:modelValue', new Date(this.modelValue.setMonth(this.modelValue.getMonth() - 1)));
      } else if (this.viewType === 'week') {
        this.$emit('update:modelValue', new Date(this.modelValue.setDate(this.modelValue.getDate() - 7)));
      }
    },
    getStringDay (day: number): string {
      return getStringDay(day, this.$t);
    },
    getStringMonth (month: number): string {
      return getStringMonth(month, this.$t);
    }
  }
});
