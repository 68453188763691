
import { defineComponent, PropType } from 'vue';
import dayjs from 'dayjs';
import { getStringDay, getStringMonth } from '@/views/Scheduler/helpers';
import { isToday } from '@/helpers/dayjs.ts';

export default defineComponent({
  name: 'SchedulerWeeklyView',
  components: {},
  props: {
    currentDay: {
      type: Object as PropType<Date>,
      required: true
    },
    events: {
      type: Array as PropType<any[]>,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ['set-date', 'show-event'],
  data () {
    return {
    };
  },
  computed: {
    from (): Date {
      const from = new Date(this.currentDay);
      const dayOfWeek = this.currentDay.getDay();
      return new Date(from.setDate(from.getDate() - (dayOfWeek ? dayOfWeek - 1 : 6)));
    },
    daysOfWeekArray (): any[] {
      return new Array(7)
        .fill(undefined)
        .map((t, i) => {
          return dayjs(this.from).add(i, 'day');
        })
        .map(day => {
          return {
            day: day.get('date'),
            fullDate: day.format(),
            dayOfWeek: day.day(),
            fullDateString: `${this.getStringDay(day.day())} ${day.get('date')}.${day.get('month') < 9 ? '0' + (day.get('month') + 1) : (day.get('month') + 1)}`
          };
        });
    }
  },
  methods: {
    getStringDay (day: number): string {
      return getStringDay(day, this.$t);
    },
    getStringMonth (month: number): string {
      return getStringMonth(month, this.$t);
    },
    findEventsByDay (day: number) {
      return this.events
        .map(event => {
          return {
            day: dayjs(event.start).get('date'),
            ...event
          };
        })
        .filter(e => e.day === day);
    },
    setDate (date: string) {
      this.$emit('set-date', date);
    },
    showEvent (id: string) {
      this.$emit('show-event', id);
    },
    isTodayClass (date: string): string | null {
      return isToday(date) ? 'weekly-view__day-header--today' : null;
    }
  }
});
