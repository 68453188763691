
import { defineComponent, PropType } from 'vue';
import { TimingScheduler, QuantityScheduler, SchedulerBar } from './_components';
import { CalendarResource } from '@/api/models';
import { filterAndSortEvents } from '@/views/Scheduler/helpers';

export default defineComponent({
  name: 'SchedulerDailyView',
  components: { TimingScheduler, QuantityScheduler, SchedulerBar },
  props: {
    resources: {
      type: Array as PropType<CalendarResource[]>,
      required: true
    },
    currentDay: {
      type: Object as PropType<Date>,
      required: true
    },
    events: {
      type: Array as PropType<any[]>,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    draggedEvent: {
      type: Object as PropType<HTMLElement | null>,
      default: null
    },
    componentKey: {
      type: Number as PropType<number>,
      default: 0
    }
  },
  emits: [
    'change:eventDateTiming',
    'change:eventDateQuantity',
    'change:eventDuration',
    'update:draggedEvent',
    'create-new-event',
    'reorder-events',
    'show-event',
    'change:placeholderDuration',
    'create-new-placeholder',
    'show-placeholder',
    'delete-placeholder'
  ],
  data () {
    return {
      slotHeight: 45 as number,
      scrolling: false as boolean,
      scrollingDelay: 20 as number,
      scrollingOffset: 10 as number
    };
  },
  computed: {
    eventsWithResourceId (): string[] {
      return this.events.map((e: any) => e.resourceId);
    }
  },
  methods: {
    getResourceVisiblityHours (externalConfig: any) {
      if (externalConfig) {
        return externalConfig.visibility.find((el: any) => el.day === this.currentDay.getDay());
      }
    },
    filterEvents (resourceId: string, quantitative: boolean) {
      return filterAndSortEvents(this.events, resourceId, quantitative);
    },
    async scrolltop () {
      this.scrolling = true;
      (document.getElementsByTagName('html')[0] as HTMLElement).style.scrollBehavior = 'unset';
      while (this.scrolling && window.pageYOffset > 0) {
        await this.createDelay(this.scrollingDelay);
        window.scrollTo(0, window.pageYOffset - this.scrollingOffset);
      }
    },
    async scrollbottom () {
      this.scrolling = true;
      (document.getElementsByTagName('html')[0] as HTMLElement).style.scrollBehavior = 'unset';
      while (this.scrolling) {
        await this.createDelay(this.scrollingDelay);
        window.scrollTo(0, window.pageYOffset + this.scrollingOffset);
      }
    },
    endScrolling () {
      this.scrolling = false;
      (document.getElementsByTagName('html')[0] as HTMLElement).style.scrollBehavior = 'smooth';
    },
    async createDelay (ms: number): Promise<any> {
      // eslint-disable-next-line promise/param-names
      return new Promise(res => setTimeout(res, ms));
    }
  }
});
