import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppModalHeader = _resolveComponent("AppModalHeader")
  const _component_DmCheckbox = _resolveComponent("DmCheckbox")
  const _component_AppModalBody = _resolveComponent("AppModalBody")
  const _component_AppModalButton = _resolveComponent("AppModalButton")
  const _component_AppModalFooter = _resolveComponent("AppModalFooter")
  const _component_AppModal = _resolveComponent("AppModal")

  return (_openBlock(), _createBlock(_component_AppModal, {
    "model-value": _ctx.modelValue,
    class: _ctx.$bem({})
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AppModalHeader, {
        title: _ctx.$t('common.attention'),
        "with-close-button": "",
        onClose: _ctx.close
      }, null, 8, ["title", "onClose"]),
      _createVNode(_component_AppModalBody, null, {
        default: _withCtx(() => [
          _createVNode("div", {
            class: _ctx.$bem({e: 'info'})
          }, _toDisplayString(_ctx.$t('calendar.moveToCalendar.confirmChange')), 3),
          _createVNode(_component_DmCheckbox, {
            id: "date-change-message",
            modelValue: _ctx.sendConfirmation,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendConfirmation = $event)),
            rounded: "",
            class: _ctx.$bem({e: 'send-message'})
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('calendar.moveToCalendar.sendSms')), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "class"])
        ]),
        _: 1
      }),
      _createVNode(_component_AppModalFooter, null, {
        buttons: _withCtx(() => [
          _createVNode(_component_AppModalButton, {
            color: "primary",
            dark: "",
            class: _ctx.$bem({e: 'confirmation-button'}),
            onClick: _ctx.confirm
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.confirm')), 1)
            ]),
            _: 1
          }, 8, ["class", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value", "class"]))
}