
import { defineComponent } from 'vue';

import { DmIcon } from '@dobrymechanik/vue-ui';

export default defineComponent({
  name: 'SchedulerBar',
  components: { DmIcon },
  props: {
    resource: {
      type: Object,
      required: true
    },
    eventsId: {
      type: Array,
      default: () => {
        return [];
      }
    },
    externalConfig: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: ''
    },
    day: {
      type: Number,
      default: 0
    }
  },
  computed: {
    occupiedSlots (): number {
      return this.eventsId.filter((e: any) => e === this.resource.id).length;
    },
    occupiedBarWidth (): number | null {
      return this.capacity ? this.occupiedSlots / this.capacity * 100 : null;
    },
    capacity (): number | null {
      return this.externalConfig && this.type === 'quantitative' ? this.externalConfig.capacity : null;
    },
    isShared (): boolean {
      if (!this.externalConfig) return false;

      if (this.type === 'quantitative') {
        return !!this.externalConfig.admissionHours.find((e: any) => e.day === this.day);
      } else {
        return !!this.externalConfig.visibility.find((e: any) => e.day === this.day);
      }
    },
    progressLabel (): string {
      return `${this.occupiedSlots}/${this.capacity}`;
    },
    visibilityLabel (): string | undefined {
      if (this.isShared && this.type === 'scheduled') {
        const currentVisibility = this.externalConfig.visibility.find((e: any) => e.day === this.day);
        const from = currentVisibility.from.split(':');
        const to = currentVisibility.to.split(':');
        return from && to ? `${from[0]}:${from[1]} - ${to[0]}:${to[1]}` : undefined;
      }
      return undefined;
    }
  }
});
