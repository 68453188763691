import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmTile = _resolveComponent("DmTile")
  const _component_AppModalHeader = _resolveComponent("AppModalHeader")
  const _component_AppModalButton = _resolveComponent("AppModalButton")
  const _component_SchedulerNewEventSimpleForm = _resolveComponent("SchedulerNewEventSimpleForm")
  const _component_AppEventForm = _resolveComponent("AppEventForm")
  const _component_AppModalBody = _resolveComponent("AppModalBody")
  const _component_AppModalFooter = _resolveComponent("AppModalFooter")
  const _component_AppModal = _resolveComponent("AppModal")
  const _component_SchedulerNewEventModalBrowser = _resolveComponent("SchedulerNewEventModalBrowser")

  return (_openBlock(), _createBlock(_component_AppModal, {
    "model-value": _ctx.modelValue,
    persistent: "",
    class: _ctx.$bem({}),
    loading: _ctx.loading,
    "data-test": "new-event-modal",
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AppModalHeader, {
        title: _ctx.title,
        "with-close-button": "",
        onClose: _ctx.close
      }, {
        default: _withCtx(() => [
          _createVNode("div", {
            class: _ctx.$bem({ e: 'repair-datetime' })
          }, _toDisplayString(_ctx.repairDatetime), 3),
          _createVNode(_component_DmTile, {
            class: _ctx.$bem({ e: 'tabs' }),
            rounded: "",
            bordered: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tabItems, (tab, index) => {
                return (_openBlock(), _createBlock(_component_DmButton, {
                  key: index,
                  color: "primary",
                  hoverable: "",
                  plain: index !== _ctx.activeTabIndex,
                  dark: index === _ctx.activeTabIndex,
                  class: _ctx.$bem({ e: 'tab-button' }),
                  "data-test": "tab-button",
                  onClick: ($event: any) => (_ctx.selectTab(index))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(tab.text), 1)
                  ]),
                  _: 2
                }, 1032, ["plain", "dark", "class", "onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }, 8, ["title", "onClose"]),
      _withDirectives(_createVNode("div", {
        class: _ctx.$bem({ e: 'content' })
      }, [
        (!_ctx.isFormExtended)
          ? (_openBlock(), _createBlock(_component_SchedulerNewEventSimpleForm, {
              key: 0,
              ref: "SchedulerNewEventSimpleForm",
              start: _ctx.start,
              "onUpdate:start": _cache[2] || (_cache[2] = ($event: any) => (_ctx.start = $event)),
              "hour-to-select": _ctx.hourToSelect,
              "initial-values": _ctx.initialValues,
              onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submit($event, _ctx.uriCreate, true, 'createByPartialData'))),
              onValueExists: _ctx.openAsyncCheckModal
            }, {
              bottom: _withCtx((values) => [
                _createVNode(_component_DmButton, {
                  class: _ctx.$bem({ e: 'toggle-form-button', m: 'more' }),
                  plain: "",
                  color: "primary",
                  hoverable: "",
                  block: "",
                  "data-test": "new-event-show-more",
                  onClick: ($event: any) => (_ctx.toggleFormMode(values))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('newRepair.showMore')), 1)
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"])
              ]),
              footer: _withCtx(() => [
                _createVNode(_component_AppModalButton, {
                  color: "primary",
                  dark: "",
                  type: "submit",
                  rounded: "",
                  class: _ctx.$bem({ e: 'submit-button' }),
                  "data-test": "new-event-submit",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSubmitClick(true)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('newRepair.add')), 1)
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _: 1
            }, 8, ["start", "hour-to-select", "initial-values", "onValueExists"]))
          : (_openBlock(), _createBlock(_component_AppEventForm, {
              key: 1,
              start: _ctx.start,
              "onUpdate:start": _cache[5] || (_cache[5] = ($event: any) => (_ctx.start = $event)),
              "hour-to-select": _ctx.hourToSelect,
              "initial-values": _ctx.initialValues,
              onSubmit: _cache[6] || (_cache[6] = ($event: any) => (_ctx.submit($event, _ctx.uriCreate, true, 'createByFullData'))),
              onValueExists: _ctx.openAsyncCheckModal
            }, {
              bottom: _withCtx((values) => [
                _createVNode(_component_DmButton, {
                  class: _ctx.$bem({ e: 'toggle-form-button' }),
                  plain: "",
                  color: "primary",
                  hoverable: "",
                  block: "",
                  "data-test": "new-event-show-less",
                  onClick: ($event: any) => (_ctx.toggleFormMode(values))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('newRepair.showLess')), 1)
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"])
              ]),
              footer: _withCtx(() => [
                _createVNode(_component_AppModalButton, {
                  color: "primary",
                  dark: "",
                  type: "submit",
                  rounded: "",
                  class: _ctx.$bem({ e: 'submit-button' }),
                  "data-test": "new-event-submit",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSubmitClick(true)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('newRepair.add')), 1)
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _: 1
            }, 8, ["start", "hour-to-select", "initial-values", "onValueExists"])),
        _createVNode(_component_AppModal, {
          modelValue: _ctx.isAsyncCheckModalShown,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.isAsyncCheckModalShown = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AppModalHeader, {
              title: _ctx.$t('common.attention')
            }, null, 8, ["title"]),
            _createVNode(_component_AppModalBody, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('repair.clientExists')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_AppModalFooter, null, {
              buttons: _withCtx(() => [
                _createVNode(_component_AppModalButton, {
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isAsyncCheckModalShown = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_AppModalButton, {
                  color: "primary",
                  dark: "",
                  "data-test": "check-existing-client-button",
                  onClick: _ctx.searchQueryInBrowser
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.checkout')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ], 2), [
        [_vShow, _ctx.activeTabIndex === 0]
      ]),
      _withDirectives(_createVNode("div", {
        class: _ctx.$bem({ e: 'content' })
      }, [
        _createVNode(_component_SchedulerNewEventModalBrowser, {
          ref: "SchedulerNewEventModalBrowser",
          "initial-values": _ctx.initialValues,
          onAddNewClient: _cache[9] || (_cache[9] = ($event: any) => (_ctx.activeTabIndex = 0)),
          onSubmit: _cache[10] || (_cache[10] = ($event: any) => (_ctx.submit($event, _ctx.uriCreate, false, 'createByReturningClient'))),
          onSubmitWithNewCar: _cache[11] || (_cache[11] = ($event: any) => (_ctx.submit($event, _ctx.uriCreate, false, 'createByReturningClientWithNewCar'))),
          onClickSubmit: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onSubmitClick(false)))
        }, null, 8, ["initial-values"])
      ], 2), [
        [_vShow, _ctx.activeTabIndex === 1]
      ])
    ]),
    _: 1
  }, 8, ["model-value", "class", "loading", "onClose"]))
}