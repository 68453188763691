import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"
import _imports_0 from '@/assets/images/dm-logo-white.png'


const _hoisted_1 = {
  key: 0,
  class: "scheduler-event__car"
}
const _hoisted_2 = { class: "scheduler-event__phone" }
const _hoisted_3 = { class: "scheduler-event__reason" }
const _hoisted_4 = { class: "scheduler-event__external" }
const _hoisted_5 = {
  key: 0,
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmIcon = _resolveComponent("DmIcon")

  return (_openBlock(), _createBlock("div", {
    id: _ctx.event.id,
    ref: _ctx.event.id,
    duration: _ctx.event.duration,
    class: ["scheduler-event", { 'scheduler-event--disabled': _ctx.event.statusOrder > 2, 'scheduler-event--placeholder': _ctx.event.type === 'placeholder' }]
  }, [
    _createVNode("div", {
      class: ["scheduler-event__wrapper", `scheduler-event__wrapper--${_ctx.event.statusOrder || 0}`],
      style: { 'height': _ctx.height + 'px' }
    }, [
      _createVNode("div", {
        class: "scheduler-event__description",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showEvent && _ctx.showEvent(...args)))
      }, [
        (_ctx.event.type === 'repair')
          ? (_openBlock(), _createBlock("div", _hoisted_1, _toDisplayString(_ctx.event.brand || '———') + " " + _toDisplayString(_ctx.event.model || '———'), 1))
          : _createCommentVNode("", true),
        _createVNode("div", _hoisted_2, _toDisplayString(_ctx.event.phone), 1),
        _createVNode("div", _hoisted_3, _toDisplayString(_ctx.reason), 1)
      ]),
      _createVNode("div", _hoisted_4, [
        (_ctx.event.isExternal)
          ? (_openBlock(), _createBlock("img", _hoisted_5))
          : (_ctx.event.type === 'placeholder')
            ? (_openBlock(), _createBlock(_component_DmIcon, {
                key: 1,
                class: "scheduler-event__delete-placeholder",
                name: "trash",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('delete-placeholder', _ctx.event.id)))
              }))
            : _createCommentVNode("", true)
      ]),
      _createVNode("div", {
        class: "scheduler-event__resize",
        onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.mousedown && _ctx.mousedown(...args)))
      }, null, 32)
    ], 6)
  ], 10, ["id", "duration"]))
}