
import { defineComponent, PropType } from 'vue';
import dayjs from 'dayjs';
import { getStringDay, getStringMonth } from '@/views/Scheduler/helpers';
import { isToday } from '@/helpers/dayjs.ts';

export default defineComponent({
  name: 'SchedulerMonthlyView',
  components: {},
  props: {
    currentDay: {
      type: Object as PropType<Date>,
      required: true
    },
    events: {
      type: Array as PropType<any[]>,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ['show-event', 'set-date'],
  data () {
    return {
      hoverDay: null as null | number
    };
  },
  computed: {
    from (): any {
      return dayjs(this.currentDay).startOf('month').get('date');
    },
    to (): any {
      return dayjs(this.currentDay).endOf('month').get('date');
    },
    daysOfMonthArray (): any[] {
      return new Array(this.to)
        .fill(undefined)
        .map((t, i) => {
          return dayjs(this.currentDay).startOf('month').add(i, 'day');
        })
        .map(day => {
          return {
            day: day.get('date'),
            fullDate: day.format(),
            dayOfWeek: day.day(),
            fullDateString: `${this.getStringDay(day.day())} ${day.get('date')}.${day.get('month') < 9 ? '0' + (day.get('month') + 1) : (day.get('month') + 1)}`
          };
        });
    }
  },
  methods: {
    getStringDay (day: number): string {
      return getStringDay(day, this.$t);
    },
    getStringMonth (month: number): string {
      return getStringMonth(month, this.$t);
    },
    findEventsByDay (day: number) {
      return this.events
        .map(event => {
          return {
            day: dayjs(event.start).get('date'),
            ...event
          };
        })
        .filter(e => e.day === day);
    },
    setHoverDay (day: number | null) {
      this.hoverDay = day;
    },
    showEvent (id: string) {
      this.$emit('show-event', id);
    },
    setDate (date: string) {
      this.$emit('set-date', date);
    },
    isToday (date: string): boolean {
      return isToday(date);
    }
  }
});
