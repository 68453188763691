
import { defineComponent, PropType } from 'vue';
import {
  DmTile,
  DmButton
} from '@dobrymechanik/vue-ui';
import {
  AppSpelledWord,
  AppTextHighlighter,
  AppPhoneNumber
} from '@/components';
import { Client, ClientCar } from '@/api/models';

export default defineComponent({
  name: 'SchedulerNewEventModalBrowserItem',
  components: {
    DmTile,
    DmButton,
    AppSpelledWord,
    AppTextHighlighter,
    AppPhoneNumber
  },
  props: {
    data: {
      type: Object as PropType<Client>,
      required: true
    },
    searchText: {
      type: String as PropType<string>,
      required: true
    }
  },
  emits: ['select-client'],
  methods: {
    selectCar (car: ClientCar): void {
      this.$emit('select-client', {
        client: this.data,
        car
      });
    },
    addCar (): void {
      this.$emit('select-client', {
        client: this.data,
        car: null
      });
    },
    clientName (firstName: string | null, lastName: string | null): string {
      return `${firstName || '—————'} ${lastName || '—————'}`;
    }
  }
});
