import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmInput = _resolveComponent("DmInput")
  const _component_AppModalHeader = _resolveComponent("AppModalHeader")
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_SchedulerNewEventModalBrowserItem = _resolveComponent("SchedulerNewEventModalBrowserItem")
  const _component_DmAsyncContent = _resolveComponent("DmAsyncContent")
  const _component_AppModalBody = _resolveComponent("AppModalBody")
  const _component_AppNewEventForm = _resolveComponent("AppNewEventForm")

  return (_openBlock(), _createBlock("div", {
    class: _ctx.$bem({}),
    "data-test": "new-event-tab-returning"
  }, [
    (!_ctx.isFormOpen)
      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_AppModalHeader, { bordered: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_DmInput, {
                ref: "searchInput",
                modelValue: _ctx.searchText,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchText = $event)),
                rounded: "",
                light: "",
                "no-hint": "",
                "right-icon": _ctx.searchText.length > 0 ? 'close' : 'magnifier',
                "right-icon-clickable": _ctx.searchText.length > 0,
                placeholder: _ctx.$t('common.search'),
                "data-test": "new-event-tab-returning-browser",
                onInput: _ctx.onSearchTextInput,
                onClickRightIcon: _ctx.cancelSearchResults
              }, null, 8, ["modelValue", "right-icon", "right-icon-clickable", "placeholder", "onInput", "onClickRightIcon"])
            ]),
            _: 1
          }),
          _createVNode(_component_AppModalBody, {
            class: _ctx.$bem({ e: 'content' })
          }, {
            default: _withCtx(() => [
              (_ctx.isIntroShown)
                ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                    _createVNode("p", {
                      class: _ctx.$bem({ e: 'intro-main' })
                    }, _toDisplayString(_ctx.$t('newRepair.browser.hint')), 3),
                    _createVNode("p", {
                      class: _ctx.$bem({ e: 'intro-description' })
                    }, _toDisplayString(_ctx.$t('newRepair.browser.example')), 3)
                  ], 64))
                : (_openBlock(), _createBlock(_component_DmAsyncContent, {
                    key: 1,
                    loading: _ctx.loading,
                    "loading-text": _ctx.$t('newRepair.browser.loading'),
                    error: _ctx.error,
                    reloadable: "",
                    onReload: _cache[2] || (_cache[2] = ($event: any) => (_ctx.search(_ctx.searchText)))
                  }, {
                    default: _withCtx(() => [
                      (_ctx.items.length === 0)
                        ? (_openBlock(), _createBlock("div", _hoisted_1, [
                            _createVNode("p", {
                              class: _ctx.$bem({ e: 'no-items-text' })
                            }, _toDisplayString(_ctx.$t('newRepair.browser.noMatchingItems')), 3),
                            _createVNode(_component_DmButton, {
                              color: "primary",
                              dark: "",
                              rounded: "",
                              block: "",
                              onClick: _ctx.addNewClient
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('newRepair.browser.addClient')), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]))
                        : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                              return (_openBlock(), _createBlock(_component_SchedulerNewEventModalBrowserItem, {
                                key: item.id,
                                data: item,
                                "search-text": _ctx.highlightText,
                                onSelectClient: _ctx.selectClient
                              }, null, 8, ["data", "search-text", "onSelectClient"]))
                            }), 128)),
                            _createVNode(_component_DmButton, {
                              color: "primary",
                              dark: "",
                              rounded: "",
                              block: "",
                              onClick: _ctx.addNewClient
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('newRepair.browser.addClient')), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ], 64))
                    ]),
                    _: 1
                  }, 8, ["loading", "loading-text", "error"]))
            ]),
            _: 1
          }, 8, ["class"])
        ], 64))
      : (_openBlock(), _createBlock(_component_AppNewEventForm, {
          key: 1,
          client: _ctx.selectedClient,
          "initial-reason": _ctx.initialValues.reason,
          car: _ctx.selectedCar,
          "has-go-back-button": "",
          onSubmit: _ctx.submit,
          onClickSubmit: _ctx.onSubmitClick,
          onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isFormOpen = false))
        }, null, 8, ["client", "initial-reason", "car", "onSubmit", "onClickSubmit"]))
  ], 2))
}