import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "scheduler-bar" }
const _hoisted_2 = { class: "scheduler-bar__wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "has-margin-left-sm"
}
const _hoisted_4 = {
  key: 1,
  class: "scheduler-bar__progress"
}
const _hoisted_5 = {
  key: 0,
  class: "scheduler-bar__occupied-free"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmIcon = _resolveComponent("DmIcon")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("h3", null, _toDisplayString(_ctx.resource.title), 1),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DmIcon, {
        class: ["scheduler-bar__icon-grey", { 'scheduler-bar__icon-blue': _ctx.isShared }],
        name: "wifi",
        size: "lg"
      }, null, 8, ["class"]),
      (_ctx.visibilityLabel)
        ? (_openBlock(), _createBlock("div", _hoisted_3, _toDisplayString(_ctx.visibilityLabel), 1))
        : _createCommentVNode("", true),
      (_ctx.capacity && _ctx.isShared)
        ? (_openBlock(), _createBlock("div", _hoisted_4, [
            (!_ctx.occupiedSlots)
              ? (_openBlock(), _createBlock("div", _hoisted_5, [
                  _createVNode("div", null, _toDisplayString(_ctx.progressLabel), 1)
                ]))
              : (_openBlock(), _createBlock("div", {
                  key: 1,
                  style: { width: _ctx.occupiedBarWidth + '%'},
                  class: [{ 'scheduler-bar__occupied--full': _ctx.occupiedBarWidth > 80}, "scheduler-bar__occupied"]
                }, [
                  _createVNode("div", null, _toDisplayString(_ctx.progressLabel), 1)
                ], 6))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}