export function filterAndSortEvents (events: any[], resourceId: string, quantitative: boolean) {
  const filteredEvents = events.filter(event => event.resourceId === resourceId);

  if (quantitative) {
    filteredEvents.sort((a, b) => {
      if (a.positionInWorkspace && b.positionInWorkspace) {
        return a.positionInWorkspace > b.positionInWorkspace ? 1 : -1;
      } else {
        return 1;
      }
    });
  }
  return filteredEvents;
}

export function getStringDay (day: number, $t: Function): any {
  switch (day) {
  case 0: return $t('days.sunday');
  case 1: return $t('days.monday');
  case 2: return $t('days.tuesday');
  case 3: return $t('days.wednesday');
  case 4: return $t('days.thursday');
  case 5: return $t('days.friday');
  case 6: return $t('days.saturday');
  }
}

export function getStringMonth (month: number, $t: Function): any {
  switch (month) {
  case 0: return $t('months.january');
  case 1: return $t('months.february');
  case 2: return $t('months.march');
  case 3: return $t('months.april');
  case 4: return $t('months.may');
  case 5: return $t('months.june');
  case 6: return $t('months.july');
  case 7: return $t('months.august');
  case 8: return $t('months.september');
  case 9: return $t('months.october');
  case 10: return $t('months.november');
  case 11: return $t('months.december');
  }
}
