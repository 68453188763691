import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "weekly-view__days" }
const _hoisted_2 = { class: "weekly-view__day-label" }
const _hoisted_3 = { class: "weekly-view__events-quantity" }
const _hoisted_4 = { class: "weekly-view__day-event-car" }
const _hoisted_5 = { class: "weekly-view__phone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ["weekly-view", { 'weekly-view--disabled': _ctx.loading }]
  }, [
    _createVNode("div", _hoisted_1, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.daysOfWeekArray, (day) => {
        return (_openBlock(), _createBlock("div", {
          key: day.fullDate,
          class: "weekly-view__day"
        }, [
          _createVNode("div", {
            class: ["weekly-view__day-header", _ctx.isTodayClass(day.fullDate)],
            onClick: ($event: any) => (_ctx.setDate(day.fullDate))
          }, [
            _createVNode("div", _hoisted_2, _toDisplayString(day.fullDateString), 1),
            _createVNode("div", _hoisted_3, _toDisplayString(_ctx.findEventsByDay(day.day).length || null), 1)
          ], 10, ["onClick"]),
          _createVNode("div", {
            class: ["weekly-view__events", { 'weekly-view__events--weekend': day.dayOfWeek === 6 || day.dayOfWeek === 0 }]
          }, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.findEventsByDay(day.day), (event) => {
              return (_openBlock(), _createBlock("div", {
                key: event.id,
                class: ["weekly-view__event", `weekly-view__event--${event.statusOrder}`],
                onClick: ($event: any) => (_ctx.showEvent(event.id))
              }, [
                _createVNode("div", _hoisted_4, _toDisplayString(event.brand || '———') + " " + _toDisplayString(event.model || '———'), 1),
                _createVNode("div", _hoisted_5, _toDisplayString(event.phone), 1),
                _createVNode("div", null, _toDisplayString(event.reason), 1)
              ], 10, ["onClick"]))
            }), 128))
          ], 2)
        ]))
      }), 128))
    ])
  ], 2))
}