import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "timing-scheduler__row-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchedulerEvent = _resolveComponent("SchedulerEvent")
  const _component_AppEventActionModal = _resolveComponent("AppEventActionModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", {
      class: ["timing-scheduler", { 'timing-scheduler--disabled': _ctx.loading }]
    }, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.slotsArray, (slot) => {
        return (_openBlock(), _createBlock("div", {
          key: slot,
          class: ["timing-scheduler__row", { 'timing-scheduler__row--multiple': _ctx.findSlots(slot).length > 1 }],
          time: _ctx.parseData(slot),
          eventStart: !!_ctx.findSlot(slot)
        }, [
          _createVNode("div", _hoisted_1, _toDisplayString(_ctx.getRowLabel(slot)), 1),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.findSlots(slot), (event, index) => {
            return (_openBlock(), _createBlock(_component_SchedulerEvent, {
              key: `slot-${index}`,
              event: event,
              "multiple-row": _ctx.findSlots(slot).length > 1,
              draggable: event.statusOrder < 3,
              "slot-height": _ctx.slotHeight,
              onDragstart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dragstart($event))),
              onDragend: _ctx.dragend,
              "onChangeEvent:duration": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('change:eventDuration', $event))),
              "onChangePlaceholder:duration": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('change:placeholderDuration', $event))),
              onShowEvent: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('show-event', $event))),
              onShowPlaceholder: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('show-placeholder', $event))),
              onDeletePlaceholder: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('delete-placeholder', $event)))
            }, null, 8, ["event", "multiple-row", "draggable", "slot-height", "onDragend"]))
          }), 128)),
          _createVNode("div", {
            class: ["timing-scheduler__add", { 'timing-scheduler__add--hover': !_ctx.dragging, 'timing-scheduler__add--visible': _ctx.checkSlotVisibility(slot) }],
            onDragover: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"])),
            onDragenter: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.dragenter && _ctx.dragenter(...args))),
            onDragleave: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.dragleave && _ctx.dragleave(...args))),
            onDrop: ($event: any) => (_ctx.drop($event, slot)),
            onClick: ($event: any) => (_ctx.addCalendarEvent(slot))
          }, null, 42, ["onDrop", "onClick"])
        ], 10, ["time", "eventStart"]))
      }), 128))
    ], 2),
    _createVNode(_component_AppEventActionModal, {
      modelValue: _ctx.modal,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.modal = $event)),
      onCreateNewPlaceholder: _ctx.createNewPlaceholder,
      onCreateNewEvent: _ctx.createNewEvent
    }, null, 8, ["modelValue", "onCreateNewPlaceholder", "onCreateNewEvent"])
  ], 64))
}