import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, mergeProps as _mergeProps, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmSelect = _resolveComponent("DmSelect")
  const _component_DmPhoneInput = _resolveComponent("DmPhoneInput")
  const _component_DmField = _resolveComponent("DmField")
  const _component_DmTextarea = _resolveComponent("DmTextarea")
  const _component_AppModalBody = _resolveComponent("AppModalBody")
  const _component_AppModalFooter = _resolveComponent("AppModalFooter")
  const _component_DmForm = _resolveComponent("DmForm")

  return (_openBlock(), _createBlock(_component_DmForm, {
    "initial-values": _ctx.initialValues,
    "validation-schema": _ctx.validationSchema,
    class: _ctx.$bem({}),
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(({ errors, setFieldValue, values, submitCount }) => [
      _createVNode(_component_AppModalBody, null, {
        default: _withCtx(() => [
          _createVNode("div", {
            class: _ctx.$bem({ e: 'section' })
          }, [
            (_ctx.hourToSelect)
              ? (_openBlock(), _createBlock("div", _hoisted_1, [
                  _createVNode("h3", {
                    class: _ctx.$bem({ e: 'section-header' })
                  }, _toDisplayString(_ctx.$t('newRepair.repairHour')), 3),
                  _createVNode(_component_DmSelect, {
                    "model-value": _ctx.start,
                    class: "has-margin-bottom-lg",
                    items: _ctx.workingHoursSlots,
                    rounded: "",
                    light: "",
                    onChange: _ctx.change
                  }, null, 8, ["model-value", "items", "onChange"])
                ]))
              : _createCommentVNode("", true),
            _createVNode("h3", {
              class: _ctx.$bem({ e: 'section-header' })
            }, _toDisplayString(_ctx.$t('clients.sectionTitle')), 3),
            _createVNode(_component_DmField, { name: "phone" }, {
              default: _withCtx(({ validate }) => [
                _createVNode(_component_DmPhoneInput, {
                  ref: "phoneInput",
                  modelValue: values.phone,
                  "onUpdate:modelValue": ($event: any) => (values.phone = $event),
                  valid: _ctx.isPhoneValid,
                  "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isPhoneValid = $event)),
                  "error-text": _ctx.$t('clients.rules.phone'),
                  light: "",
                  rounded: "",
                  required: "",
                  lazy: submitCount === 0,
                  hint: _ctx.$t('clients.hints.phone'),
                  "default-country-code": _ctx.defaultCountryCode,
                  "data-test": "field-phone",
                  label: `${_ctx.$t('clients.fields.phone')} *`,
                  onValidate: validate,
                  onBlur: ($event: any) => {
              _ctx.asyncFieldCheck({ name: 'phone', value: values.phone }, setFieldValue);
              validate();
              _ctx.isPhoneTouched = true;
            }
                }, null, 8, ["modelValue", "onUpdate:modelValue", "valid", "error-text", "lazy", "hint", "default-country-code", "label", "onValidate", "onBlur"])
              ]),
              _: 2
            }, 1024)
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'section' })
          }, [
            _createVNode("h3", {
              class: _ctx.$bem({ e: 'section-header' })
            }, _toDisplayString(_ctx.$t('repair.sectionTitle')), 3),
            _createVNode(_component_DmField, { name: "reason" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_component_DmTextarea, _mergeProps({
                  modelValue: field.value,
                  "onUpdate:modelValue": ($event: any) => (field.value = $event)
                }, field, {
                  light: "",
                  rounded: "",
                  rows: 3,
                  error: errors.reason,
                  "data-test": "field-notes",
                  label: `${_ctx.$t('repair.fields.reason')} *`,
                  required: ""
                }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
              ]),
              _: 2
            }, 1024)
          ], 2),
          _renderSlot(_ctx.$slots, "bottom", {
            phone: values.phone,
            reason: values.reason
          })
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_AppModalFooter, null, {
        buttons: _withCtx(() => [
          _renderSlot(_ctx.$slots, "footer", {
            phone: values.phone,
            reason: values.reason
          })
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["initial-values", "validation-schema", "class", "onSubmit"]))
}