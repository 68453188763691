
import { defineComponent, PropType } from 'vue';
import {
  DmModal,
  DmTextarea
} from '@dobrymechanik/vue-ui';
import {
  AppModalHeader,
  AppModalButton,
  AppModalFooter,
  AppModalBody
} from '@/components';
import { EventMetadata } from '@/models/events';

export default defineComponent({
  name: 'SchedulerQuantityPlaceholderModal',
  components: { DmModal, AppModalHeader, DmTextarea, AppModalButton, AppModalFooter, AppModalBody },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    metadata: {
      type: Object as PropType<EventMetadata | null>,
      default: null
    },
    placeholderId: {
      type: String,
      default: ''
    },
    events: {
      type: Array as PropType<any[]>,
      default: () => {
        return [];
      }
    }
  },
  emits: ['update:model-value', 'submit'],
  data () {
    return {
      notes: '',
      slots: 0
    };
  },
  computed: {
    title (): string {
      return this.metadata?.workspace || '';
    },
    occupiedSlots (): number {
      return this.events.filter((e: any) => e.resourceId === this.metadata?.workspaceId).length;
    },
    maxSlotToBlock (): number {
      return this.metadata?.externalConfig.capacity - this.occupiedSlots;
    }
  },
  methods: {
    updateModelValue (value: boolean) {
      this.$emit('update:model-value', value);
    },
    submit () {
      const data = {
        type: 'quantitative',
        payload: {
          reason: this.notes,
          quantity: Number(this.maxSlotToBlock - this.slots),
          workspaceId: this.metadata?.workspaceId,
          whenAt: this.metadata?.startStr
        }
      };
      this.$emit('submit', data);
    }
  }
});
