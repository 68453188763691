
import { defineComponent, PropType } from 'vue';
import {
  DmModal,
  DmTextarea
} from '@dobrymechanik/vue-ui';
import {
  AppModalHeader,
  AppModalButton,
  AppModalFooter,
  AppModalBody
} from '@/components';
import { EventMetadata } from '@/models/events';

export default defineComponent({
  name: 'SchedulerTimingPlaceholderModal',
  components: { DmModal, AppModalHeader, DmTextarea, AppModalButton, AppModalFooter, AppModalBody },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    metadata: {
      type: Object as PropType<EventMetadata | null>,
      default: null
    },
    placeholderId: {
      type: String,
      default: ''
    }
  },
  emits: ['update:model-value', 'submit'],
  data () {
    return {
      notes: ''
    };
  },
  computed: {
    title (): string {
      return this.metadata?.workspace || '';
    },
    repairDatetime (): string {
      const startStr = this.metadata?.start;
      if (startStr) {
        const start = new Date(startStr);
        const end = new Date(start.getTime() + 45 * 60 * 1000);
        const startDatetime = start.toLocaleTimeString(this.$i18n.locale, {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
        const endHour = end.toLocaleTimeString(this.$i18n.locale, {
          hour: '2-digit',
          minute: '2-digit'
        });
        return `${startDatetime} - ${endHour}`;
      }
      return '';
    }
  },
  methods: {
    updateModelValue (value: boolean) {
      this.$emit('update:model-value', value);
    },
    submit () {
      const data = {
        type: 'scheduled',
        payload: {
          reason: this.notes,
          fromAt: this.metadata?.startStr,
          duration: 45,
          workspaceId: this.metadata?.workspaceId
        }
      };
      this.$emit('submit', data);
    }
  }
});
