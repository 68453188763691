
import { defineComponent, PropType } from 'vue';
import {
  DmPhoneInput,
  DmSelect,
  DmTextarea
} from '@dobrymechanik/vue-ui';
import {
  AppModalBody,
  AppModalFooter
} from '@/components';
import {
  Form,
  Field
} from 'vee-validate';
import { EventFormData } from '@/models/events';
import {
  URI_CLIENT_BY_PHONE
} from '@/api/endpoints';
import {
  CarBrand,
  RepairReasonGroup
} from '@/api/models';
import { mapState, mapActions } from 'vuex';
import {
  FETCH_REPAIR_REASONS
} from '@/store/repair/actions';
import { createWorkingHoursSlots } from '@/helpers/functions';
import { WorkspaceWorkingHours, WorkspaceSlot } from '@/models/workspaces';

export default defineComponent({
  name: 'SchedulerNewEventModalSimpleForm',
  components: {
    DmPhoneInput,
    DmForm: Form,
    DmField: Field,
    AppModalBody,
    AppModalFooter,
    DmSelect,
    DmTextarea
  },
  props: {
    initialValues: {
      type: Object as PropType<EventFormData>,
      required: true
    },
    start: {
      type: String,
      default: ''
    },
    hourToSelect: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'submit',
    'value-exists',
    'start-hour-changed',
    'update:start'
  ],
  data () {
    return {
      isReadyToSubmit: true as boolean,
      selectedBrand: null as CarBrand | null,
      submitWaitingObject: null as EventFormData | null,
      isPhoneValid: false as boolean,
      defaultCountryCode: 'PL' as string
    };
  },
  computed: {
    ...mapState('repair', [
      'repairReasons',
      'repairReasonsLoading',
      'repairReasonsError'
    ]),
    validationSchema () {
      return {
        phone: () => this.isPhoneValid || this.$t('clients.rules.phone'),
        reason: (v: any) => (v && v.length) || this.$t('repair.rules.reason')
      };
    },
    reasons (): any {
      const flattenedRepairReasons = (this.repairReasons as RepairReasonGroup[]).map(g => {
        return [
          {
            header: g.text
          },
          ...g.items.map(r => {
            return {
              key: r.id,
              text: r.text
            };
          })
        ];
      });

      const reasons = ([] as any[]).concat(...flattenedRepairReasons);
      return reasons;
    },
    workingHoursSlots (): WorkspaceWorkingHours[] {
      const startTime = '07:00:00';
      const endTime = '20:00:00';
      const slot = WorkspaceSlot.ThirtyMin;
      return createWorkingHoursSlots(startTime, endTime, slot);
    }
  },
  mounted () {
    this.fetchRepairReasons(this.$http);
  },
  methods: {
    setInitialPhoneNumber (initialPhoneNumber: string) {
      const phoneInput = (this.$refs.phoneInput as any);
      phoneInput.updatePhoneNumber(initialPhoneNumber);
      phoneInput.onBlur();
    },
    change (value: any) {
      this.$emit('update:start', value.key);
    },
    ...mapActions('repair', {
      fetchRepairReasons: FETCH_REPAIR_REASONS
    }),
    updateSelectedReason (item: any, setFieldValue: any, validate: any): void {
      setFieldValue('reason', {
        id: item?.key || null,
        text: item?.text || ''
      });
      validate();
    },
    call (fn: any): void {
      fn();
    },
    submit (values: EventFormData): void {
      if (this.isReadyToSubmit) {
        this.submitWaitingObject = null;
        this.$emit('submit', {
          ...values,
          reason: values.reason,
          reasonId: null
        });
      } else {
        this.submitWaitingObject = values;
      }
    },
    async asyncFieldCheck (field: { value: any; name: string }, setFieldValue: any): Promise<void> {
      const checkMap: { [key in string]: any } = {
        phone: {
          initialValue: this.initialValues.phone,
          uri: URI_CLIENT_BY_PHONE
        }
      };
      const checkDetails = checkMap[field.name];
      if (checkDetails && field.value && field.value !== checkDetails.initialValue) {
        this.isReadyToSubmit = false;
        try {
          const valueExists = (await this.$http.get<{ data: boolean }>(checkDetails.uri(field.value))).data.data;
          if (valueExists) {
            setFieldValue(field.name, checkDetails.initialValue);
            this.$emit('value-exists', {
              name: field.name,
              value: field.value
            });
          }
        } catch (e) {
          this.isPhoneValid = false;
        }
        this.isReadyToSubmit = true;
        if (this.submitWaitingObject) {
          this.submit(this.submitWaitingObject);
        }
      }
    },
    reloadRepairReasons (): void {
      this.fetchRepairReasons(this.$http);
    }
  }
});
