import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "scheduler__headers daily-view__headers" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "scheduler__workspaces daily-view__workspaces" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchedulerBar = _resolveComponent("SchedulerBar")
  const _component_TimingScheduler = _resolveComponent("TimingScheduler")
  const _component_QuantityScheduler = _resolveComponent("QuantityScheduler")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.resources, (resource) => {
          return (_openBlock(), _createBlock(_component_SchedulerBar, {
            key: resource.id,
            resource: resource,
            "external-config": resource.externalConfig,
            type: resource.type,
            "events-id": _ctx.eventsWithResourceId,
            day: _ctx.currentDay.getDay()
          }, null, 8, ["resource", "external-config", "type", "events-id", "day"]))
        }), 128))
      ])
    ]),
    _createVNode("div", _hoisted_3, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.resources, (resource) => {
        return (_openBlock(), _createBlock("div", {
          key: resource.id,
          class: "scheduler__workspace daily-view__workspace"
        }, [
          (resource.type === 'scheduled')
            ? (_openBlock(), _createBlock(_component_TimingScheduler, {
                key: _ctx.componentKey + 'timing',
                loading: _ctx.loading,
                date: _ctx.currentDay,
                "dragged-event": _ctx.draggedEvent,
                events: _ctx.filterEvents(resource.id),
                "slot-height": _ctx.slotHeight,
                visibility: _ctx.getResourceVisiblityHours(resource.externalConfig),
                "onUpdate:draggedEvent": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:draggedEvent', $event))),
                "onChange:eventDate": ($event: any) => (_ctx.$emit('change:eventDateTiming', { data: $event, resourceId: resource.id })),
                "onChange:eventDuration": ($event: any) => (_ctx.$emit('change:eventDuration', { data: $event, resourceId: resource.id })),
                "onChange:placeholderDuration": ($event: any) => (_ctx.$emit('change:placeholderDuration', { data: $event, resourceId: resource.id })),
                onShowEvent: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('show-event', $event))),
                onShowPlaceholder: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('show-placeholder', $event))),
                onCreateNewEvent: ($event: any) => (_ctx.$emit('create-new-event', { resource, start: $event })),
                onCreateNewPlaceholder: ($event: any) => (_ctx.$emit('create-new-placeholder', { resource, start: $event })),
                onDeletePlaceholder: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('delete-placeholder', $event)))
              }, null, 8, ["loading", "date", "dragged-event", "events", "slot-height", "visibility", "onChange:eventDate", "onChange:eventDuration", "onChange:placeholderDuration", "onCreateNewEvent", "onCreateNewPlaceholder"]))
            : (_openBlock(), _createBlock(_component_QuantityScheduler, {
                key: _ctx.componentKey + 'quantity',
                "dragged-event": _ctx.draggedEvent,
                loading: _ctx.loading,
                events: _ctx.filterEvents(resource.id, true),
                "slot-height": _ctx.slotHeight,
                "resource-external-config": resource.externalConfig,
                "current-day": _ctx.currentDay,
                "onUpdate:draggedEvent": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:draggedEvent', $event))),
                "onChange:eventDate": ($event: any) => (_ctx.$emit('change:eventDateQuantity', resource.id)),
                "onChange:eventDuration": ($event: any) => (_ctx.$emit('change:eventDuration', { data: $event, resourceId: resource.id })),
                onShowEvent: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('show-event', $event))),
                onCreateNewEvent: ($event: any) => (_ctx.$emit('create-new-event', { resource })),
                onReorderEvents: ($event: any) => (_ctx.$emit('reorder-events', { data: $event, resourceId: resource.id })),
                onCreateNewPlaceholder: ($event: any) => (_ctx.$emit('create-new-placeholder', { resource })),
                onDeletePlaceholder: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('delete-placeholder', $event)))
              }, null, 8, ["dragged-event", "loading", "events", "slot-height", "resource-external-config", "current-day", "onChange:eventDate", "onChange:eventDuration", "onCreateNewEvent", "onReorderEvents", "onCreateNewPlaceholder"]))
        ]))
      }), 128))
    ]),
    (!!_ctx.draggedEvent)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: "daily-view__scroll daily-view__scroll--top",
          onDragenter: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.scrolltop && _ctx.scrolltop(...args))),
          onDragleave: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.endScrolling && _ctx.endScrolling(...args)))
        }, null, 32))
      : _createCommentVNode("", true),
    (!!_ctx.draggedEvent)
      ? (_openBlock(), _createBlock("div", {
          key: 1,
          class: "daily-view__scroll daily-view__scroll--bottom",
          onDragenter: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.scrollbottom && _ctx.scrollbottom(...args))),
          onDragleave: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.endScrolling && _ctx.endScrolling(...args)))
        }, null, 32))
      : _createCommentVNode("", true)
  ], 64))
}