
import { defineComponent, PropType } from 'vue';
import SchedulerEvent from '../SchedulerEvent/Component.vue';
import {
  AppEventActionModal
} from '@/components';

export default defineComponent({
  name: 'TimingScheduler',
  components: { SchedulerEvent, AppEventActionModal },
  props: {
    date: {
      type: Object as PropType<Date>,
      required: true
    },
    events: {
      type: Array as PropType<any[]>,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    draggedEvent: {
      type: Object as PropType<Node>,
      default: null
    },
    slotHeight: {
      type: Number,
      required: true
    },
    visibility: {
      type: Object,
      default: null
    }
  },
  emits: [
    'change:eventDate',
    'update:draggedEvent',
    'change:eventDuration',
    'show-event',
    'create-new-event',
    'change:placeholderDuration',
    'create-new-placeholder',
    'show-placeholder',
    'delete-placeholder'
  ],
  data () {
    return {
      duration: 15 as number,
      dragging: false as boolean,
      modal: false,
      currentSlot: null
    };
  },
  computed: {
    slotsArray (): any[] {
      const startTime = {
        h: 0,
        m: 0
      };
      const endTime = {
        h: 23,
        m: 45
      };
      return this.slots(this.date, startTime, endTime, this.duration);
    },
    draggedEventDuration (): number {
      return parseInt((this.draggedEvent as HTMLElement)?.getAttribute('duration') || '0');
    }
  },
  methods: {
    addCalendarEvent (slot: any) {
      this.currentSlot = slot;
      if (this.checkSlotVisibility(slot)) {
        this.modal = true;
      } else {
        this.createNewEvent();
      }
    },
    createNewEvent () {
      this.modal = false;
      this.$emit('create-new-event', this.currentSlot);
    },
    createNewPlaceholder () {
      this.modal = false;
      this.$emit('create-new-placeholder', this.currentSlot);
    },
    slots (d: Date, startTime: any, endTime: any, duration: number) {
      const start = d.setHours(startTime.h, startTime.m, 0);
      const end = d.setHours(endTime.h, endTime.m, 0);
      const arr = [];
      let last = 0;
      let item = 0;
      while (last < end) {
        last = start + item * duration * 60 * 1000;
        arr.push(last);
        item = item + 1;
      }

      return arr.map(item => new Date(item));
    },
    parseData (slot: any) {
      return Date.parse(slot);
    },
    getRowLabel (slot: any) {
      const hour = slot.getHours().toString().length === 1 ? `0${slot.getHours()}` : slot.getHours();
      const minutes = slot.getMinutes().toString().length === 1 ? `0${slot.getMinutes()}` : slot.getMinutes();
      return `${hour}:${minutes}`;
    },
    findSlot (slot: any) {
      return this.events.find((event: any) => {
        return Date.parse(event.start) === Date.parse(slot);
      });
    },
    findSlots (slot: any) {
      return this.events.filter((event: any) => {
        return Date.parse(event.start) === Date.parse(slot);
      });
    },
    dragleave (e: Event) {
      (e.target as HTMLElement).classList.remove('timing-scheduler__add--dragover');
      (e.target as HTMLElement).style.minHeight = `${this.slotHeight}px`;
    },
    dragenter (e: Event) {
      // if (this.isAbleToDrop(e)) {
      //   (e.target as HTMLElement).style.minHeight = (this.draggedEventDuration / this.duration) * this.slotHeight + 'px';
      //   (e.target as HTMLElement).classList.add('timing-scheduler__add--dragover');
      // }

      (e.target as HTMLElement).style.minHeight = (this.draggedEventDuration / this.duration) * this.slotHeight + 'px';
      (e.target as HTMLElement).classList.add('timing-scheduler__add--dragover');
    },
    isAbleToDrop (e: Event) {
      let row = ((e.target as HTMLElement).parentElement as HTMLElement).nextElementSibling;
      const draggedEventTime = ((this.draggedEvent as HTMLElement).parentElement as HTMLElement).getAttribute('time');
      const sameResource = ((this.draggedEvent as HTMLElement).parentElement as HTMLElement).parentElement?.id === ((e.target as HTMLElement).parentElement as HTMLElement).parentElement?.id;
      let freeSlots = 1;
      while (row) {
        if ((row as HTMLElement).getAttribute('eventstart') === 'true' && ((row as HTMLElement).getAttribute('time') !== draggedEventTime || !sameResource)) {
          break;
        }
        row = (row as HTMLElement).nextElementSibling;
        freeSlots += 1;
      }

      return freeSlots >= (this.draggedEventDuration / this.duration);
    },
    dragstart (e: Event) {
      this.$emit('update:draggedEvent', (e.target as HTMLElement));

      setTimeout(() => ((e.target as HTMLElement).style.opacity = '0'), 0);

      const draggedElementId = (e.target as HTMLElement).id;
      const eventsWitoutDragged = [...document.getElementsByClassName('scheduler-event')].filter(el => el.id !== draggedElementId);
      eventsWitoutDragged.forEach(el => {
        (el as HTMLElement).style.pointerEvents = 'none';
      });

      this.dragging = true;
    },
    dragend (e: Event) {
      (e.target as HTMLElement).style.opacity = '1';
      this.$emit('update:draggedEvent', null);
      this.dragging = false;
    },
    drop (e: Event, slot: Date) {
      // check if is possible to drop event
      // if (!this.isAbleToDrop(e)) return;

      // append dragged element to slot
      if (this.draggedEvent) {
        (this.draggedEvent as HTMLElement).classList.remove('scheduler-event--quantity');
        ((e.target as HTMLElement).parentElement as HTMLElement).append(this.draggedEvent);
      }

      // remove dragover class
      (e.target as HTMLElement).classList.remove('timing-scheduler__add--dragover');

      // mark slot as busy - remove add event HTML element
      const addEventElement = ((e.target as HTMLElement).parentElement as HTMLElement).getElementsByClassName('timing-scheduler__add')[0];
      addEventElement.remove();

      const data = {
        plannedRepairAt: slot,
        duration: (this.draggedEvent as HTMLElement).getAttribute('duration') || '',
        sendConfirmation: 0
      };

      this.$emit('change:eventDate', data);
    },
    checkSlotVisibility (slot: any) {
      if (!this.visibility) return false;
      const slotDate = new Date();
      slotDate.setHours(slot.getHours(), slot.getMinutes());

      const fromArray = this.visibility.from.split(':');
      const fromDate = new Date();
      fromDate.setHours(fromArray[0], fromArray[1]);

      const toArray = this.visibility.to.split(':');
      const toDate = new Date();
      toDate.setHours(toArray[0], toArray[1]);

      return slotDate.valueOf() >= fromDate.valueOf() && slotDate.valueOf() < toDate.valueOf();
    }
  }
});
