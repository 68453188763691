
import { defineComponent } from 'vue';
import {
  DmIcon
} from '@dobrymechanik/vue-ui';

export default defineComponent({
  name: 'SchedulerEvent',
  components: { DmIcon },
  props: {
    event: {
      type: Object,
      required: true
    },
    quantity: {
      type: Boolean,
      default: false
    },
    slotHeight: {
      type: Number,
      required: true
    },
    multipleRow: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'change-placeholder:duration',
    'show-event',
    'show-placeholder',
    'change-event:duration',
    'delete-placeholder'
  ],
  data () {
    return {
      mousedownY: 0 as number,
      height: this.event.duration * 3 as number,
      calculatedHeight: 0 as number,
      slotQuantity: 0 as number
    };
  },
  computed: {
    maxSlotsToResize (): number {
      let row = ((document.getElementById(this.event.id) as HTMLElement).parentElement as HTMLElement).nextElementSibling;
      let freeSlots = 1;

      while (row) {
        if ((row as HTMLElement).getAttribute('eventstart') === 'true') {
          break;
        }
        row = (row as HTMLElement).nextElementSibling;
        freeSlots += 1;
      }
      return freeSlots;
    },
    reason (): string {
      return this.multipleRow && this.event.reason.length > 8
        ? this.event.reason.slice(0, 8) + '...'
        : this.event.reason;
    }
  },
  methods: {
    mousedown (e: MouseEvent) {
      if (this.event.statusOrder > 2) return;
      e.preventDefault();
      this.mousedownY = e.clientY;
      this.calculatedHeight = this.height;
      window.addEventListener('mousemove', this.whileMove);
      window.addEventListener('mouseup', this.endMove);
    },

    whileMove (e: MouseEvent) {
      const diff = e.clientY - this.mousedownY;
      let height = this.calculatedHeight;
      const slotQuantity = Math.ceil(diff / this.slotHeight);
      height += this.slotHeight * slotQuantity;

      if (slotQuantity !== this.slotQuantity) {
        this.slotQuantity = slotQuantity;
      }
      if (height >= this.slotHeight && height <= this.maxSlotsToResize * this.slotHeight) {
        this.height = height;
      }
    },

    endMove () {
      const data = {
        duration: this.height / 3,
        eventId: this.event.id
      };

      if (this.$refs[this.event.id]) {
        (this.$refs[this.event.id] as HTMLElement).setAttribute('duration', data.duration.toString());
      }

      if (this.calculatedHeight && this.calculatedHeight !== this.height) {
        if (this.event.type === 'repair') {
          this.$emit('change-event:duration', data);
        } else {
          const eData = {
            ...this.event,
            duration: data.duration
          };
          this.$emit('change-placeholder:duration', eData);
        }
      }
      window.removeEventListener('mousemove', this.whileMove);
      window.removeEventListener('mouseup', this.endMove);
    },

    showEvent (): void {
      if (this.event.type === 'repair') {
        this.$emit('show-event', this.event.id);
      } else {
        this.$emit('show-placeholder', this.event);
      }
    }
  }
});
