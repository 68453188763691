import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "monthly-view__days" }
const _hoisted_2 = { class: "monthly-view__day-events-quantity" }
const _hoisted_3 = { class: "monthly-view__day-event-car" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ["monthly-view", { 'monthly-view--disabled': _ctx.loading }]
  }, [
    _createVNode("div", _hoisted_1, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.daysOfMonthArray, (day, index) => {
        return (_openBlock(), _createBlock("div", {
          key: day.fullDate,
          style: { 'margin-left': index === 0 ? (day.dayOfWeek === 0 ? 6 : day.dayOfWeek - 1) * 178 + 'px' : null },
          class: "monthly-view__day"
        }, [
          _createVNode("div", {
            class: ["monthly-view__day-label", { 'monthly-view__day-label--hover': _ctx.hoverDay === day.day, 'monthly-view__day-label--today': _ctx.isToday(day.fullDate) }],
            onClick: ($event: any) => (_ctx.setDate(day.fullDate))
          }, _toDisplayString(day.fullDateString), 11, ["onClick"]),
          _createVNode("div", {
            class: ["monthly-view__day-events-wrapper", { 'monthly-view__day-events-wrapper--weekend': day.dayOfWeek === 6 || day.dayOfWeek === 0 }],
            onMouseover: ($event: any) => (_ctx.setHoverDay(_ctx.findEventsByDay(day.day).length ? day.day : null))
          }, [
            _createVNode("div", _hoisted_2, _toDisplayString(_ctx.findEventsByDay(day.day).length ? _ctx.findEventsByDay(day.day).length : null), 1),
            (_ctx.findEventsByDay(day.day).length && _ctx.hoverDay === day.day)
              ? (_openBlock(), _createBlock("div", {
                  key: 0,
                  class: "monthly-view__day-events",
                  onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setHoverDay(null)))
                }, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.findEventsByDay(day.day), (event) => {
                    return (_openBlock(), _createBlock("div", {
                      key: event.id,
                      class: ["monthly-view__day-event", `monthly-view__day-event--${event.statusOrder}`],
                      onClick: ($event: any) => (_ctx.showEvent(event.id))
                    }, [
                      _createVNode("div", _hoisted_3, _toDisplayString(event.brand || '———') + " " + _toDisplayString(event.model || '———'), 1),
                      _createVNode("div", null, _toDisplayString(event.reason), 1)
                    ], 10, ["onClick"]))
                  }), 128))
                ], 32))
              : _createCommentVNode("", true)
          ], 42, ["onMouseover"])
        ], 4))
      }), 128))
    ])
  ], 2))
}